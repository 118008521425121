import React, {Fragment} from "react";
import {Helmet} from "react-helmet-async";
import {OneColumnLayout} from "../../components/Layout/OneColumnLayout";
import {Profile} from "../../components/Profile/Profile";

export function ProfilePage() {

  return (
    <Fragment>
      <Helmet>
        <title>Профиль</title>
      </Helmet>
      <OneColumnLayout content={<Profile />} />
    </Fragment>
  );
}
