import s from "./HiddenProblem.module.css";
import {Link} from "react-router-dom";
import {courseIdByProblemSlug} from "../../domain/problems/maxcode/courseIdByProblemSlug";
import {Problem} from "../../api/problems";
import {Breadcrumbs} from "../ProblemPage/Breadcrumbs/Breadcrumbs";
import cn from "classnames";
import {courseId2topics} from "../../domain/problems/courses";
import {MarkdownRenderer} from "../ProblemPage/ProblemDescription/MarkdownRenderer";

interface HiddenProblemProps {
  slug: string;
  problem: Problem | undefined;
}

export function HiddenProblem({slug, problem}: HiddenProblemProps) {
  const courseId = courseIdByProblemSlug(slug);

  return (
    <div className={s.wrapper}>
      {problem !== undefined && (
        <div className={s.breadcrumbs}>
          <Breadcrumbs problem={problem}/>
        </div>
      )}
      {problem !== undefined && problem.description !== null && (
        <div className={s.excerpt}>
          <MarkdownRenderer children={problem.description}/>
        </div>
      )}
      <div className={s.message}>
        <h1>{slug}</h1>
        {courseId !== "async" ? (
          <p>Эта задача доступна только при покупке курса</p>
        ) : (
          <p>Эта задача доступна только <Link to={"/promo/" + courseId}>при покупке курса</Link></p>
        )}
        <p><Link to={"/courses/" + courseId}>Вернуться к списку задач</Link></p>
      </div>
    </div>
  );
}
