export const dfs = [
  {
    section: "DFS на матрицах",
    problems: [
      {
        title: "130. Surrounded Regions",
        slug: "surrounded-regions",
      },
      {
        title: "200. Number of Islands",
        slug: "number-of-islands",
      },
      {
        title: "695. Max Area of Island",
        slug: "max-area-of-island",
      },
      {
        title: "733. Flood Fill",
        slug: "flood-fill",
      },
      {
        title: "529. Minesweeper",
        slug: "minesweeper",
      },
    ],
  },
  {
    section: "DFS на графах",
    problems: [
      {
        title: "1376. Time Needed to Inform All Employees",
        slug: "time-needed-to-inform-all-employees",
      },
      {
        title: "133. Clone Graph",
        slug: "clone-graph",
      },
      {
        title: "690. Employee Importance",
        slug: "employee-importance",
      },
      {
        title: "547. Number of Provinces",
        slug: "number-of-provinces",
      },
      {
        title: "399. Evaluate Division",
        slug: "evaluate-division",
      },
      {
        title: "785. Is Graph Bipartite?",
        slug: "is-graph-bipartite",
      },
      {
        title: "886. Possible Bipartition",
        slug: "is-graph-bipartite",
      },
      {
        title: "841. Keys and Rooms",
        slug: "keys-and-rooms",
      },
      {
        title: "839. Similar String Groups",
        slug: "similar-string-groups",
      },
      {
        title: "802. Find Eventual Safe States",
        slug: "find-eventual-safe-states",
      },
      {
        title: "1034. Coloring A Border",
        slug: "coloring-a-border",
      },
    ],
  },
  {
    section: "Топологическая сортировка",
    problems: [
      {
        title: "207. Course Schedule",
        slug: "course-schedule",
      },
      {
        title: "210. Course Schedule II",
        slug: "course-schedule-ii",
      },
    ],
  },
];
