export const binTreeTopics = [
  {
    section: "Рекурсивный обход",
    problems: [
      {
        id: 104,
        title: "Maximum Depth of Binary Tree",
        slug: "maximum-depth-of-binary-tree",
        difficulty: "medium",
      },
      {
        id: 110,
        title: "Balanced Binary Tree",
        slug: "balanced-binary-tree",
        difficulty: "medium",
      },
      {
        id: 112,
        title: "Path Sum",
        slug: "path-sum",
        difficulty: "medium",
      },
      {
        id: 113,
        title: "Path Sum II",
        slug: "path-sum-ii",
        difficulty: "medium",
      },
      {
        id: 222,
        title: "Count Complete Tree Nodes",
        slug: "count-complete-tree-nodes",
        difficulty: "medium",
      },
      {
        id: 236,
        title: "Lowest Common Ancestor of a Binary Tree",
        slug: "lowest-common-ancestor-of-a-binary-tree",
        difficulty: "medium",
      },
      {
        id: 508,
        title: "Most Frequent Subtree Sum",
        slug: "most-frequent-subtree-sum",
        difficulty: "medium",
      },
    ],
  },
  {
    section: "Binary Search Tree",
    problems: [
      {
        id: 700,
        title: "Search in a Binary Search Tree",
        slug: "search-in-a-binary-search-tree",
        difficulty: "medium",
      },
      {
        id: 108,
        title: "Convert Sorted Array to Binary Search Tree",
        slug: "convert-sorted-array-to-binary-search-tree",
        difficulty: "medium",
      },
      {
        id: 98,
        title: "Validate Binary Search Tree",
        slug: "validate-binary-search-tree",
        difficulty: "medium",
      },
    ],
  },
  {
    section: "Обход дерева: pre-order, post-order и in-order",
    problems: [
      {
        id: 94,
        title: "Binary Tree Inorder Traversal",
        slug: "binary-tree-inorder-traversal",
        difficulty: "medium",
      },
      {
        id: 144,
        title: "Binary Tree Preorder Traversal",
        slug: "binary-tree-preorder-traversal",
        difficulty: "medium",
      },
      {
        id: 145,
        title: "Binary Tree Postorder Traversal",
        slug: "binary-tree-postorder-traversal",
        difficulty: "medium",
      },
      {
        id: 102,
        title: "Binary Tree Level Order Traversal",
        slug: "binary-tree-level-order-traversal",
        difficulty: "medium",
      },
      {
        id: 530,
        title: "Minimum Absolute Difference in BST",
        slug: "minimum-absolute-difference-in-bst",
        difficulty: "medium",
      },
    ],
  },
  {
    section: "Модификация дерева",
    problems: [
      {
        id: 450,
        title: "Delete Node in a BST",
        slug: "delete-node-in-a-bst",
        difficulty: "medium",
      },
      {
        id: 623,
        title: "Add One Row to Tree",
        slug: "add-one-row-to-tree",
        difficulty: "medium",
      },
      {
        id: 669,
        title: "Trim a Binary Search Tree",
        slug: "trim-a-binary-search-tree",
        difficulty: "medium",
      },
      {
        id: 703,
        title: "Insert into a Binary Search Tree",
        slug: "insert-into-a-binary-search-tree",
        difficulty: "medium",
      },
      {
        id: 814,
        title: "Binary Tree Pruning",
        slug: "binary-tree-pruning",
        difficulty: "medium",
      },
      {
        id: 1110,
        title: "Delete Nodes And Return Forest",
        slug: "delete-nodes-and-return-forest",
        difficulty: "medium",
      },
    ],
  },
  {
    section: "Расстояния в дереве",
    problems: [
      {
        id: 543,
        title: "Diameter of Binary Tree",
        slug: "diameter-of-binary-tree",
        difficulty: "medium",
      },
      {
        id: 662,
        title: "Maximum Width of Binary Tree",
        slug: "maximum-width-of-binary-tree",
        difficulty: "medium",
      },
      {
        id: 2385,
        title: "Amount of Time for Binary Tree to Be Infected",
        slug: "amount-of-time-for-binary-tree-to-be-infected",
        difficulty: "medium",
      },
    ],
  },
];
