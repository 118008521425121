import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchProblemsThunk } from "./problemsSlice";
import { Payment } from "../api/problems";
import { createAsyncThunk } from "../app/hooks";
import { refreshPaymentStatus } from "../api/payment"


export const paymentsSlice = createSlice({
  name: 'payments',
  initialState: [] as Payment[],
  reducers: {
    updateStatus: (state, action: PayloadAction<{
      status: string,
      yookassaPaymentId: string,
    }>) => {
      const { status, yookassaPaymentId } = action.payload;
      state.find((payment) => payment.yookassaPaymentId === yookassaPaymentId)!.status = status;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProblemsThunk.fulfilled, (state, action) => {
        return action.payload.response.payments ?? [];
      });
  },
});

export const fetchPaymentStatusThunk = createAsyncThunk(
  'payments/fetchStatus',
  (_, thunkAPI) => {
    const { payments } = thunkAPI.getState();

    payments.filter(({ status }) => status === 'pending').forEach(({ yookassaPaymentId }) => {
      const fetcher = () => {
        console.log('fetch', yookassaPaymentId);
        return refreshPaymentStatus(yookassaPaymentId);
      };

      const isCompleted = (status: string) => status !== 'pending';
      polling(fetcher, isCompleted, 1000, 30).then(() => {
        thunkAPI.dispatch(fetchProblemsThunk());
      }, (reason) => {
        console.log(reason);
      })
    });
  });

function sleep(delay: number) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}

function polling<T>(
  fetcher: () => Promise<T>,
  isCompleted: (result: T) => boolean,
  delay: number,
  retries: number,
): Promise<T> {
  if (retries === 0) {
    return Promise.reject(new Error("Run out of retries"));
  }

  return fetcher()
    .then((result) => {
      if (isCompleted(result)) {
        return result;
      }

      throw new Error('Go wait!');
    })
    .catch(() => {
      return sleep(delay).then(() => {
        return polling(fetcher, isCompleted, delay, retries - 1);
      });
    });
}
