export const MathMethods = [
  {
    method: "Math.abs",
    argument: "number",
    returnedValue: "number",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "Math.ceil",
    argument: "number",
    returnedValue: "number",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "Math.floor",
    argument: "number",
    returnedValue: "number",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "Math.max",
    argument: "numbers",
    returnedValue: "number",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "Math.min",
    argument: "numbers",
    returnedValue: "number",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "Math.random",
    argument: "—",
    returnedValue: "x ∈ [0..1)",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "Math.sqrt",
    argument: "number",
    returnedValue: "number",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  // Math.acos()
  // Math.acosh()
  // Math.asin()
  // Math.asinh()
  // Math.atan()
  // Math.atan2()
  // Math.atanh()
  // Math.cbrt()
  // Math.clz32()
  // Math.cos()
  // Math.cosh()
  // Math.exp()
  // Math.expm1()
  // Math.fround()
  // Math.hypot()
  // Math.imul()
  // Math.log()
  // Math.log10()
  // Math.log1p()
  // Math.log2()
  // Math.pow()
  // Math.round()
  // Math.sign()
  // Math.sin()
  // Math.sinh()
  // Math.tan()
  // Math.tanh()
  // Math.trunc()
] as const;

export type MathMethod = typeof MathMethods[number];

