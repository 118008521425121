import {useSelector} from "../../app/hooks";

const translations = {
  "tab.description": {
    ru: "Условие",
    en: "Problem Description",
  },
  "tab.submissions": {
    ru: "Отправки",
    en: "Submissions",
  },
  "tab.solution": {
    ru: "Решение",
    en: "Reference Solution",
  },
  "tab.buy": {
    ru: "Информация о курсе",
    en: "Buy the course",
  },
  "tab.theory": {
    ru: "Теория",
    en: "Theory",
  },
  "tab.courseDescription": {
    ru: "Список тем",
    en: "Table of contents",
  },
  "breadcrumbs.problemsList": {
    ru: "Список задач",
    en: "Problems List",
  },
  "problem.send": {
    ru: "Отправить",
    en: "Send",
  },
  "problem.reset": {
    ru: "Сбросить решение",
    en: "Reset Solution",
  },
  "problem.yourSolution": {
    ru: "Моё решение",
    en: "My Solution",
  },
  "words.problem.one": {
    ru: "задача",
    en: "problem",
  },
  "words.problem.two": {
    ru: "задачи",
    en: "problems",
  },
  "words.problem.few": {
    ru: "задач",
    en: "problems",
  },
  "submissions.testHeader": {
    ru: "Результаты проверки",
    en: "Tests",
  },
  "submissions.noSubmissionText": {
    ru: "Не было попыток отправить решение",
    en: "You should sent your first submission",
  },
  "submissions.loading": {
    ru: "Отправляю",
    en: "Loading",
  },
  "submissions.testsAreRunning": {
    ru: "Выполняются тесты",
    en: "Tests are running",
  },
  "submissions.timeLimitExplained": {
    ru: "Возможно, возникает бесконечный цикл или рекурсия",
    en: "Seems like you run an infinite loop or recursion",
  },
} as const;

type AddPrefix<TKey, TPrefix extends string> = TKey extends string
  ? `${TPrefix}${TKey}`
  : never;

type RemovePrefix<TPrefixedKey, TPrefix extends string> = TPrefixedKey extends AddPrefix<infer TKey, TPrefix>
  ? TKey
  : '';

type Split<S extends string, D extends string> =
  string extends S ? string[] :
    S extends '' ? [] :
      S extends `${infer T}${D}${infer U}` ? [T, ...Split<U, D>] : [S];

export type PluralWord = Split<Exclude<RemovePrefix<keyof typeof translations, "words.">, "">, ".">[0]

export function useTranslation() {
  const lang = useSelector(state => state.settings.lang);

  return function (path: keyof typeof translations) {
    return translations[path][lang];
  }
}
