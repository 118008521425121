import React from "react";
import {Helmet} from "react-helmet-async";
import s from "./RoadmapReact.module.css";

export function RoadmapReact() {
  return (
    <div>
      <Helmet>
        <title>
          План изучения React
        </title>
      </Helmet>
      <div className={s.wrapper}>
        <h1 className={s.mainTitle}>План изучения React</h1>
        <p>Вообще говоря, все задачи на фронтенде сводятся к двум типам: нарисовать данные, которые пришли с бэкенда, и собрать с помощью формочек данные, чтобы отправить на бэкенд.</p>
        <p>Мы реализуем некоторое количество компонентов на реакте, каждый из которых решает одну из этих задач. Компоненты взяты из тестовых заданий или придуманы по мотивам задач с моей работы.</p>
        <h2>Покемоны</h2>
        <img src="/static/roadmaps/react/Pokemons.png"/>
        <h2>Градиент</h2>
        <h2>Прогресс-бар</h2>
        <img src="/static/roadmaps/react/ProgressBar.png"/>
        <h2>Расписание отпусков</h2>
        <img src="/static/roadmaps/react/Calendar.png"/>
        <h2>Фильтры ипотеки</h2>
        <h2>Выбор региона</h2>
        <h2>Вложенные категории</h2>
        <img src="/static/roadmaps/react/Avito.png"/>
        <h2>Игра Wordle</h2>
        <img src="/static/roadmaps/react/Wordle.png"/>
      </div>
    </div>
  );
}
