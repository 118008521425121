export const bitsTopics = [
  {
    section: "Системы счисления",
    problems: [
      "int-to-decimal",
      "int-from-decimal",
      "double-to-decimal",
      "double-from-decimal",
      "rgb-to-hex",
      "hex-to-rgb",
    ],
  },
  {
    section: "Арифметика",
    problems: [
      "add-numbers",
      "multiply-numbers",
      "fast-power",
    ],
  },
  {
    section: "Дроби",
    problems: [
      "fraction-to-decimal",
      "decimal-to-fraction",
      "euclidean-algorithm",
      "mixed-number",
    ],
  },
  {
    section: "Random",
    problems: [
      "random-in-range",
      "random-rgb",
      "random-hex",
    ],
  },
  {
    section: "Представление чисел",
    problems: [
      "int32",
      "double64",
    ],
  },
  {
    section: "Операции с битами",
    problems: [
      "count-bits",
      "eliminate-zero-bits",
      "next-higher-number",
    ],
  },
] as const;

