import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {HelmetProvider} from "react-helmet-async";
import {store} from './app/store';
import {App} from './App';
import './index.css';
import { loader } from '@monaco-editor/react';
import * as monaco from 'monaco-editor';
loader.config({ monaco });

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <HelmetProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </HelmetProvider>
);
