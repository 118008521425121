import {
    TypedUseSelectorHook,
    useDispatch as useDispatchRedux,
    useSelector as useSelectorRedux,
} from 'react-redux';
import { createAsyncThunk as createAsyncThunkRedux } from "@reduxjs/toolkit";
import type { RootState, AppDispatch } from './store';

export const useDispatch = () => useDispatchRedux<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useSelectorRedux;

export const createAsyncThunk = createAsyncThunkRedux.withTypes<{
  state: RootState
  dispatch: AppDispatch
  // rejectValue: string
  // extra: {}
}>();
