import {Link} from "react-router-dom";
import {GithubAuthLink} from "../../components/GithubAuthLink/GithubAuthLink";
import s from "./AsyncCourseLanding.module.css";
import screen from "./screen.png";
import {CourseProblemsList} from "../../components/CoursePage/CourseProblemsList/CourseProblemsList";
import React from "react";
import {AsyncProblemsDescription} from "../../components/CoursePage/CourseDescription/AsyncCourseDescription";
import {CoursesList} from "../../components/CoursesList/CoursesList";
import {fpCourse, oopCourse, recursionCourse} from "../../domain/problems/MaxcodeCourses";
import {useSelector} from "../../app/hooks";
import {Helmet} from "react-helmet-async";
import {formatPrice} from "../../domain/formatPrice";

export function AsyncCourseLanding() {
  const user = useSelector(s => s.user.user);
  const courses = useSelector(s => s.user.courses);
  const asyncCourse = courses.find(course => ["async-pro", "async"].includes(course.courseId));

  return (
    <div className={s.wrapper}>
      <Helmet>
        <title>
          Практический курс по асинхронному JavaScript
        </title>
      </Helmet>
      <h1 className={s.title}>
        Практический курс по асинхронному JavaScript
      </h1>
      <p className={s.sub}>
        Решая задачи, разбираем основные темы, связанные с асинхронностью в джаваскрипте: промисы, колбэки, таймеры,
        event loop.
      </p>
      <div className={s.content}>
        <div className={s.left}>
          <div className={s.take}>
            <h3>Практика</h3>
            <p>Научиться программированию можно только программируя. Можно посмотреть много видеоуроков и прочитать кучу
              статей, но не понять, как работает стандартный метод <code>catch</code>. А можно написать <Link
                to="/problems/catch">свой полифил</Link> — и все понять.</p>
            <p>
              Каждую задачу можно отправить на проверку в тестирующую систему а-ля LeetCode и узнать результаты
              прохождения тестов. В отличие от литкода, к каждой задаче есть авторское решение и видео с объяснением,
              как
              к такому решению прийти. Условия, тесты и решения доступны на русском языке.
            </p>
          </div>
          <div className={s.take}>
            <h3>Глубина</h3>
            <p><code>Promise.all</code> принимает массив промисов — отвечают на собеседовании. На самом деле не
              обязательно массив и не обязательно промисов. В задачах мы честно <Link to="/problems/all">рассматриваем
                все случаи</Link> и разбираемся, как оно работает на самом деле.</p>
          </div>
          <div className={s.take}>
            <h3>Полнота</h3>
            <p>За годы моего преподавания я и мои студенты сходили на сотни собеседований. Если вас спросят
              что-либо об асинхронности, то этот вопрос есть среди задач. Если появится новая интересная задача —
              обязательно добавим ее в курс.</p>
            <p>В реальности мы разбираем даже больше. Что-то слышали про функцию <code>queueMicrotask</code>, но с
              пометкой «в реальной жизни не используется»? Практические задания подобраны так, чтобы использовать
              максимум теории.</p>
          </div>
        </div>
        <Link to="/problems/all" className={s.screen}>
          <img alt="Пример задачи" src={screen} className={s.screenImage}/>
        </Link>
      </div>
      <div className={s.content}>
        <div className={s.left}>
          <div className={s.problemsDescription}>
            <AsyncProblemsDescription/>
          </div>
        </div>
        <div className={s.right}>
          <div className={s.problemsList}>
            <CourseProblemsList courseId="async"/>
          </div>
        </div>
      </div>
      <div className={s.content}>
        <div className={s.left}>
          <div className={s.inner}>

            <h2 className={s.sectionHeader}>Что входит в курс</h2>
            <p>
              Для каждой задачи доступно подробное описание с примерами на русском языке. В курсе более 30 задач разного
              уровня
              сложности.
            </p>
            <p>
              Прямо на сайте можно работать в онлайн-редакторе VS Code с привычной подсветкой и автокомплитом.
            </p>
            <p>
              В любой момент вы можете отправить свое решение на проверку. Для задач написаны тесты, покрывающие
              требования из условий.
            </p>
            <p>
              Ко всем задачам доступны авторские решения и подробные видеоразборы. Но я
              рекомендую
              в первую очередь пытаться решить задачи самостоятельно.
            </p>
          </div>
        </div>
        <div className={s.right}>
          <div className={s.inner}>
            <h2 className={s.sectionHeader}>Стоимость курса</h2>
            <p>Курс был собран по результатам многолетнего преподавания джаваскрипта в формате <a target="_blank"
                                                                                                  href="https://sinyakov.com/frontend/">индивидуальных
              занятий</a>. На данный момент стоимость занятия составляет 3000 рублей за 1 час.</p>

            <p>При двух занятиях в неделю мы разбираем асинхронность примерно за месяц. Курс содержит расширенный список
              задач.</p>

            <p>Стоимость курса и доступа к тестирующей системе:</p>
            <p className={s.price}>{formatPrice(14_900)} рублей</p>
            {user === null ? (
              <React.Fragment>
                <p><GithubAuthLink className={s.githubLink}/> Оплатить доступ можно после авторизации</p>
              </React.Fragment>
            ) : (
              asyncCourse !== undefined ? null : (
                <React.Fragment>
                  <p><Link to="/payment/?courseId=async">Оплатить курс</Link> и получить доступ к задачам</p>
                </React.Fragment>
              ))}
          </div>
        </div>
      </div>
      <h2 className={s.coursesHeader}>Бесплатные задачи на maxcode.dev</h2>
      <CoursesList courses={[fpCourse, oopCourse, recursionCourse]}/>
    </div>
  )
}
