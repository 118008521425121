import s from "./RoadmapJavaScript.module.css";

import {fpTopics} from "../../domain/problems/maxcode/topics/fpTopics";
import {oopTopics} from "../../domain/problems/maxcode/topics/oopTopics";
import {recursionTopics} from "../../domain/problems/maxcode/topics/recursionTopics";
import {asyncTopics} from "../../domain/problems/maxcode/topics/asyncTopics";
import React from "react";
import {AsyncProblem, FpProblem, OopProblem, RecursionProblem} from "../../domain/problems/MaxcodeCourses";
import {Helmet} from "react-helmet-async";
import {RoadmapProblems} from "../../components/RoadmapProblems/RoadmapProblems";
import {objectsTopics} from "../../domain/problems/maxcode/topics/objectsTopics";
import {plural, problemsPlural} from "../../domain/i18n/plural";
import {Link} from "react-router-dom";
import {arraysTopics} from "../../domain/problems/maxcode/topics/arraysTopics";

const excludedFpProblems: FpProblem[] = [
  "memo-advanced",
  "create-store",
  "combine-reducers",
  "middlewares",
  "create-slice",
  "create-async-thunk",
  // "create-listener-middleware",
  // "create-entity-adapter",
];

const excludedRecursionProblems: RecursionProblem[] = [
  // "queens",
  // "sudoku",
  // "permutations",
  // "permutations-2",
  // "perfect-squares",
  // "min-path-2d",
];

const excludedOopProblems: OopProblem[] = [];

const excludedAsyncProblems: AsyncProblem[] = [];

const fpProblems = fpTopics
  .flatMap(s => s.problems)
  .filter(id => !excludedFpProblems.includes(id));
const oopProblems = oopTopics
  .flatMap(s => s.problems)
  .filter(id => !excludedOopProblems.includes(id));
const recursionProblems = recursionTopics
  .flatMap(s => s.problems)
  .filter(id => !excludedRecursionProblems.includes(id));
const asyncProblems = asyncTopics
  .flatMap(s => s.problems)
  .filter(id => !excludedAsyncProblems.includes(id));

const sections = [
  // {id: "basics", title: "Условия и циклы", problems: basicsTopics.flatMap(t => t.problems)},
  // {id: "arrays", title: "Массивы и строки — 1", problems: arraysTopics.flatMap(t => t.problems).slice(0, 20)},
  // {id: "arrays", title: "Массивы и строки — 2", problems: arraysTopics.flatMap(t => t.problems).slice(20)},
  {id: "none", title: "Разминка", problems: arraysTopics.flatMap(t => t.problems)},
  {id: "objects", title: "Объекты и сортировки", problems: objectsTopics.flatMap(t => t.problems)},
  {id: "fp", title: "Функциональное программирование", problems: fpProblems},
  {id: "oop", title: "ООП: this, классы, прототипы", problems: oopProblems},
  {id: "recursion", title: "Рекурсия", problems: recursionProblems},
  {id: "async", title: "Асинхронность", problems: asyncProblems},
];

const total = sections.flatMap(s => s.problems).length;


export function RoadmapJavaScript() {
  return (
    <div className={s.wrapper}>
      <Helmet>
        <title>
          Практический роадмап по JavaScript
        </title>
      </Helmet>
      <h1 className={s.mainTitle}>Список задач по JavaScript</h1>
      <div className={s.preview}>
        <h2>Как изучать JavaScript</h2>
        <p>Изучение программирования основано на практике. Можно сказать, что вы уверенно знаете какую-то тему, если вы
          способны решить конкретную задачу, то есть написать код. Если вы знаете как решить «в теории», но не можете
          запрограммировать, значит, вы не знаете как решить :)</p>
        <p>Изучение языка JavaScript глобально состоит из двух частей.</p>
        <p><strong>I. Работа с данными.</strong> Здесь изучаются базовые конструкции языка (типы данных, условия,
          циклы), а также способы работы с массивами и объектами. Вы должны научиться декомпозировать задачу на
          подзадачи и решать эти подзадачи с помощью стандартных методов и подходов.</p>
        <p><strong>II. Парадигмы программирования.</strong> ФП (функциональное программирование), ООП
          (объектно-ориентированное
          программирование) и Асинхронность (асинхронное программирование) — основные парадигмы, на которых построен
          JavaScipt. Отдельно разбирается рекурсия, как важная, но не самая простая тема.</p>
        <p>Всего в
          списке {total} {problemsPlural(total)}, {plural(["разделенная", "разделенные", "разделенных"])(total)} на {sections.length} {plural(["раздел", "раздела", "разделов"])(sections.length)}.
          В среднем изучение джаваскрипта на практике занимает от 4 до 8 месяцев. После этого изучение React занимает
          примерно в два раза меньше времени — от 2 до 4 месяцев.</p>
      </div>

      <RoadmapProblems sections={sections} sectionOffset={2}/>

      <div className={s.approach}>
        <div className={s.alone}>
          <h3>Самостоятельное прохождение</h3>
          <ul>
            <li>✓ Полноценный доступ к тестирующей системе</li>
            <li>✓ Авторские решения и видеоразборы всех задач</li>
          </ul>
          {/*<p>Стоимость: от 6000 ₽ / месяц по подписке</p>*/}
          <p>На данный момент доступен курс <Link to="/promo/async">«Асинхронный JavaScript»</Link>. Остальные курсы
            откроются позже</p>
        </div>
        <div className={s.mentor}>
          <h3>Изучение с ментором</h3>
          <ul>
            <li>✓ Регулярные занятия 1 или 2 раза в неделю по 60 или 90 минут</li>
            <li>✓ Код-ревью ваших решений, разбор непонятной теории</li>
          </ul>
          <p>Стоимость: 3000 ₽ / час. <a target="_blank" href="https://sinyakov.com/frontend/">Подробнее о занятиях</a>
          </p>
        </div>
      </div>
    </div>
  );
}

