import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface Settings {
  lang: "en" | "ru",
  editorFontSize: number,
  editorFullSize: boolean,
}

export const settingsSlice = createSlice({
  name: "user",
  initialState: {
    lang: "ru",
    editorFontSize: 16,
    editorFullSize: false,
  } as Settings,
  reducers: {
    setLang(state, action: PayloadAction<Settings["lang"]>) {
      state.lang = action.payload;
    },
    toggleEditorFullSize(state) {
      state.editorFullSize = !state.editorFullSize;
    },
    increaseEditorFontSize(state) {
      state.editorFontSize += 1;
    },
    decreaseEditorFontSize(state) {
      state.editorFontSize -= 1;
    },
  },
});
