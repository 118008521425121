export const FunctionMethods = [
  {
    method: "Function#apply",
    argument: "context + array",
    returnedValue: "result",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "Function#call",
    argument: "context + arguments",
    returnedValue: "result",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "Function#bind",
    argument: "context + arguments",
    returnedValue: "function",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "Function#toString",
    argument: "—",
    returnedValue: "code",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
] as const;

export type FunctionMethod = typeof FunctionMethods[number];

