export const arraysTopics = [
  {
    section: "Массивы",
    problems: [
      "shortest-word",
      "top-words",
      "max-number",
      "2d-array-search",
      "playlist",
    ],
  }, {
    section: "Условия",
    problems: [
      "ndfl",
      "analog-clock",
    ],
  }, {
    section: "Строки",
    problems: [
      "format-price",
      "change-date-format",
      "rle-decode",
      "count-smiley-faces",
      "balance-checking",
    ],
  },
  // {
  //   section: "Массивы",
  //   problems: [
  //     "list-filtering",
  //     "shortest-word",
  //     "sum-of-positive",
  //     "build-a-square",
  //     "top-words",
  //     "change-date-format",
  //     "vowel-count",
  //     "playing-with-digits",
  //     "smallest-value",
  //     "find-the-capitals",
  //     "insert-dashes",
  //     "row-weights",
  //     "scrolling-text",
  //     "expanded-form",
  //     "playlist",
  //     "chess-rock",
  //     "chess-knight",
  //     "every-value-is-array",
  //     "make-a-square-box",
  //     "magic-index",
  //     "check-the-exam",
  //     "smiley-faces",
  //     "small-enough",
  //     "length-of-missing-array",
  //     "data-reverse",
  //     "proof-read",
  //     "format-words",
  //     "delete-digit",
  //     "equal-sides",
  //     "element-equals-its-index",
  //     "polish-notation",
  //     "zero-plentiful-array",
  // //   ],
  // // },
  // // {
  // //   section: "Вложенные циклы",
  // //   problems: [
  //     "array-diff",
  //     "homogenous-arrays",
  //     "2d-array-search",
  //     "lottery-ticket",
  //     "two-sum",
  //   ],
  // },
  // {
  //   section: "Строки",
  //   problems: [
  //     "email-obfuscator",
  //     "rgb-to-hex",
  //     "balance-checking",
  //     "valid-parentheses",
  //     "rle",
  //     "kebabize",
  //     "a10n-abbreviation",
  //     "ip-validation",
  //     "string-incrementer",
  //     "string-insert-values",
  //   ],
  // },
] as const;
