import s from "./CourseDescription.module.css";

import React from "react";
import {MethodsTable} from "../../MethodsTable/MethodsTable";
import {ObjectMethods} from "../../../domain/problems/js/ObjectMethods";
import {Link} from "react-router-dom";

export function ObjectsCourseDescription() {
  return (
    <div className={s.fp}>
      <h2>Какие темы разбираются в курсе</h2>

      <h3>Объекты</h3>

      <p>Объекты в джаваскрипте используются для двух разных целей. Во-первых, как структуры с фиксированными полями,
        описывающие какие-то сущности. Во-вторых, как словари, в которые мы добавляем произвольное количество
        ключей.</p>
      <p>У самих объектов в джаваскрипте не так много методов. Наша цель скорее научиться правильно с ними работать,
        используя способ обращения через точку и через квадратные скобки, цикл <code>for..in</code>,
        операторы <code>in</code> и <code>delete</code>.</p>

      <h3>Сортировки</h3>

      <p>Методы <code>sort</code> и <code>toSorted</code> относятся к массивам. Но так
        как в реальной жизни мы не занимается сортировкой просто чисел или просто строк, более подробно сортировку имеет
        смысл разбирать на примере сортировки объектов.</p>

      <p>Основная сложность здесь — не самый интуитивно понятный аргумент метода <code>sort</code>. В задачах
        рассматривается, как с помощью него сортировать объекты по одному ил нескольким свойствам.</p>

      <h3>Методы объектов</h3>

      <p>Для удобства здесь собраны все методы массивов. 😈 означает, что метод мутирующий и с ним нужно быть острожнее,
        😇 означает, что метод не мутирующий.</p>

      <p>При клике по заголовку методы сортируются. Ссылка ведет на MDN.</p>

      <MethodsTable methods={ObjectMethods}/>
    </div>
  );
}

