export const ObjectMethods = [
  {
    method: "Object#hasOwnProperty",
    argument: "string",
    returnedValue: "boolean",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "B",
    example: `
      const user = { name: "A", age: 34 }; 
      
      user.hasOwnProperty("name") === true
      user.hasOwnProperty("toString") === false
    `,
  },
  {
    method: "Object.assign",
    argument: "object",
    returnedValue: "object",
    mutable: true,
    nodeVersion: "4.0.0",
    year: 2015,
    tier: "A",
  },
  {
    method: "Object.create",
    argument: "proto",
    returnedValue: "object",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "B",
  },
  {
    method: "Object.defineProperties",
    argument: "properties",
    returnedValue: "—",
    mutable: true,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "B",
  },
  {
    method: "Object.defineProperty",
    argument: "property",
    returnedValue: "—",
    mutable: true,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "B",
  },
  {
    method: "Object.entries",
    argument: "object",
    returnedValue: "entries",
    mutable: false,
    nodeVersion: "7.0.0",
    year: 2016,
    tier: "A",
    example: `
      const user = { name: "A", age: 34 }; 
      
      Object.entries(user) ===
      [["name", "A"], ["age", 34]]
    `,
  },
  {
    method: "Object.fromEntries",
    argument: "entries",
    returnedValue: "object",
    mutable: false,
    nodeVersion: "12.0.0",
    year: 2019,
    tier: "A",
    example: `
      const userEntries = [["name", "A"], ["age", 34]];
      
      Object.fromEntries(userEntries) ===
      { name: "A", age: 34 }
    `,
  },
  {
    method: "Object.getOwnPropertyDescriptor",
    argument: "property",
    returnedValue: "descriptors",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "B",
  },
  {
    method: "Object.getOwnPropertyDescriptors",
    argument: "properties",
    returnedValue: "descriptors",
    mutable: false,
    nodeVersion: "7.0.0",
    year: 2016,
    tier: "B",
  },
  {
    method: "Object.groupBy",
    argument: "iterable",
    returnedValue: "object",
    mutable: false,
    nodeVersion: "21.0.0",
    year: 2023,
    tier: "A",
  },
  {
    method: "Object.hasOwn",
    argument: "property",
    returnedValue: "boolean",
    mutable: false,
    nodeVersion: "16.9.0",
    year: 2021,
    tier: "A",
  },
  {
    method: "Object.keys",
    argument: "object",
    returnedValue: "keys",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "Object.values",
    argument: "object",
    returnedValue: "values",
    mutable: false,
    nodeVersion: "7.0.0",
    year: 2016,
    tier: "A",
  },
  {
    method: "Object.is",
    argument: "values",
    returnedValue: "boolean",
    mutable: false,
    nodeVersion: "7.0.0",
    year: 2016,
    tier: "A",
  },

  // Object#isPrototypeOf()
  // Object#propertyIsEnumerable()
  // Object#toLocaleString()
  // Object#toString()
  // Object#valueOf()
  // Object.freeze()
  // Object.getOwnPropertyNames()
  // Object.getOwnPropertySymbols()
  // Object.getPrototypeOf()
  // Object.isExtensible()
  // Object.isFrozen()
  // Object.isSealed()
  // Object.preventExtensions()
  // Object.seal()
  // Object.setPrototypeOf()
] as const;

export type ObjectMethod = typeof ObjectMethods[number];

