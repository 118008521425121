import s from "./TabsSwitcher.module.css";
import {View} from "../../pages/ProblemPage/ProblemPage";
import {useTranslation} from "../../domain/i18n/i18n";

interface TabsSwitcherProps<T> {
  view: T,
  labels: { tab: T, label: string }[],
  setView: (v: T) => void,
}

export function TabsSwitcher<T>({ view, setView, labels }: TabsSwitcherProps<T>) {
  return (
    <div className={s.tabsSwitcher}>
      {labels.map(({tab, label}) => {
        return (
          <button
            key={label}
            className={s.tabsSwitcherButton}
            disabled={view === tab}
            onClick={() => setView(tab)}
          >
            {label}
          </button>
        );
      })}
    </div>
  )
}
