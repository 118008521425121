export const basicsTopics = [
  // {
  //   section: "Числа и строки",
  //   problems: [
  //     "opposite-number",
  //     "century-from-year",
  //     "ndfl",
  //     "abbreviate-a-two-word-name",
  //     "credit-card-mask",
  //     "remove-first-and-last-character",
  //     "create-phone-number",
  //   ]
  // },
  // {
  //   section: "Условия",
  //   problems: [
  //     "even-or-odd",
  //     "leap-years",
  //   ],
  // },
  // {
  //   section: "Циклы",
  //   problems: [
  //     "max-number",
  //     "reversed-strings",
  //     "count-sheep",
  //     "population",
  //     "square-sum", // https://www.codewars.com/kata/515e271a311df0350d00000f
  //     "arguments-sum",
  //     "mean-of-array", // https://www.codewars.com/kata/563e320cee5dddcf77000158
  //     "count-by-x", // https://www.codewars.com/kata/5513795bd3fafb56c200049e
  //     "divisible-by-number", // https://www.codewars.com/kata/55edaba99da3a9c84000003b
  //     "min-and-max", // https://www.codewars.com/kata/57a1ae8c7cb1f31e4e000130
  //     "sum-of-digits", // https://www.codewars.com/kata/563d59dd8e47a5ed220000ba
  //     "disemvowel-trolls", // // https://www.codewars.com/kata/52fba66badcd10859f00097e
  //     "who-likes-it", // // https://www.codewars.com/kata/5266876b8f4bf2da9b000362
  //   ],
  // },
] as const;
