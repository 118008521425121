import s from "./ProblemContent.module.css";
import {useSelector} from "../../../app/hooks";
import {TabsSwitcher} from "../../TabsSwitcher/TabsSwitcher";
import {Breadcrumbs} from "../Breadcrumbs/Breadcrumbs";
import {useParams} from "react-router-dom";
import {ContentComponent, View, views} from "../../../pages/ProblemPage/ProblemPage";
import {useTranslation} from "../../../domain/i18n/i18n";
import {courseIdByProblemSlug} from "../../../domain/problems/maxcode/courseIdByProblemSlug";
import {isSellableCourses} from "../../Payment/Payment";

interface ProblemContentProps {
  view: View,
  views: View[],
  setView: (v: View) => void,
}

export function ProblemContent({view, setView}: ProblemContentProps) {
  const translate = useTranslation();
  const slug = useParams().slug!;
  const courseId = courseIdByProblemSlug(slug)!;

  const problem = useSelector(state => state.problems[slug]);
  const Component = ContentComponent[view];

  const labels = views
    .filter(v => isSellableCourses(courseId) || problem.access.includes("SOLUTION") ? true : v !== "solution")
    .map(tab => ({tab, label: translate(`tab.${tab}`)}));

  return (
    <div className={s.wrapper}>
      <div className={s.breadcrumbs}>
        <Breadcrumbs problem={problem} />
      </div>
      <div className={s.content}>
        <Component problem={problem} />
      </div>
      <div className={s.tabs}>
        <TabsSwitcher
          view={view}
          labels={labels}
          setView={setView}
        />
      </div>
    </div>
  );
}

