export interface User {
  username: string;
  avatarUrl: string;
}

export function sendGithubCode(code: string): Promise<User> {
  return fetch("/api/v1/user/login?code=" + code, {
    credentials: 'include',
  }).then(r => r.json())
}


export function logout(): Promise<void> {
  return fetch("/api/v1/user/logout", {
    credentials: 'include',
    method: "POST",
  }).then(() => undefined);
}
