import s from "./CourseDescription.module.css";
import {Link} from "react-router-dom";
import {CourseId, courseId2topics} from "../../../domain/problems/courses";
import cn from "classnames";
import React from "react";

interface Problem {
  id: string,
  description: string,
  complexity: string,
  courseId: CourseId,
}

const problems: Problem[] = [
  {
    id: "pivot-index",
    description: "Смотрим, как сложность алгоритма влияет на время работы программы",
    complexity: "O(n²), O(n)",
    courseId: "arrays",
  },
  {
    id: "sort",
    description: "Используем арифметическую прогрессию для оценки сложности алгоритма",
    complexity: "O(n²)",
    courseId: "sorting",
  },
  {
    id: "is-prime",
    description: "Классический алгоритм проверки числа на простоту за квадратный корень",
    complexity: "O(✓n)",
    courseId: "basics",
  },
  {
    id: "subarray-sum",
    description: "Решаем одну и ту же задачу за куб, квадрат и линию",
    complexity: "O(n³), O(n²), O(n)",
    courseId: "prefix-sum",
  },
  {
    id: "range-sum-immutable",
    description: "Обсуждаем, когда для оценки алгоритма важны не только входные аргументы",
    complexity: "O(n × k), O(n + k)",
    courseId: "prefix-sum",
  },
  {
    id: "merge-sort",
    description: "Узнаем, откуда в сортировке возникает логарифм",
    complexity: "O(n × log₂n)",
    courseId: "sorting",
  },
  {
    id: "meeting-rooms",
    description: "Оцениваем пространственную сложность (использование памяти)",
    complexity: "O(n × k), O(n × log₂n)",
    courseId: "prefix-sum",
  },
  {
    id: "daily-temperatures",
    description: "Цикл в цикле не обязательно означает квадратичную сложность",
    complexity: "O(n²), O(n)",
    courseId: "stack",
  },
  {
    id: "online-stock-span",
    description: "Разберем понятие амортизированной сложности",
    complexity: "O(1) в среднем",
    courseId: "stack",
  },
  {
    id: "binary-tree-width",
    description: "Обход дерева с результатом, который не влезает в тип number",
    complexity: "O(n)",
    courseId: "bintree",
  },
  {
    id: "complete-tree-nodes",
    description: "Необычная сложность — квадрат логарифма",
    complexity: "O(log²n)",
    courseId: "bintree",
  },
  {
    id: "search-in-matrix",
    description: "Классический бинарный поиск в необычной форме",
    complexity: "O(log₂n)",
    courseId: "binsearch",
  },
  {
    id: "search-in-matrix-2",
    description: "Используем геометрическую прогрессию для оценки сложности алгоритма",
    complexity: "O(N), O(N ** log₂3)",
    courseId: "binsearch",
  },
  {
    id: "first-and-last-in-sorted-array",
    description: "Разберем понятие инварианта и что делает функция lower_bound",
    complexity: "O(log₂n)",
    courseId: "binsearch",
  },
];

export function AlgoCourseDescription() {
  return (
    <div className={s.async}>
      <h2>Краткое описание задач</h2>
      <div>
        {problems.map(({id, description, courseId, complexity}) => (
          <div className={s.tableRow}>
            <div className={s.tableHeader}>
              <div className={s.tableProblem}><Link to={"/problems/" + id}>{id}</Link></div>
              <div className={s.tableComplexity}>{complexity}</div>
              <div className={s.tableCourse}>
                <Link to={"/courses/" + courseId} className={cn(s.course, courseId)}>
                  {courseId2topics[courseId].ru.title}
                </Link>
              </div>
            </div>
            <div className={s.tableDescription}>{description}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
