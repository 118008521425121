import s from "./CheatsheetJavaScript.module.css";
import React from "react";
import {Helmet} from "react-helmet-async";
import {MethodsTable} from "../../components/MethodsTable/MethodsTable";
import {ArrayMethods} from "../../domain/problems/js/ArrayMethods";
import {StringMethods} from "../../domain/problems/js/StringMethods";
import {MathMethods} from "../../domain/problems/js/MathMethods";
import {ObjectMethods} from "../../domain/problems/js/ObjectMethods";
import {RegExpMethods} from "../../domain/problems/js/RegExpMethods";
import {MapMethods} from "../../domain/problems/js/MapMethods";
import {JavascriptMethod} from "../../domain/problems/js/JavascriptMethods";
import {SetMethods} from "../../domain/problems/js/SetMethods";
import {NumberMethods} from "../../domain/problems/js/NumberMethods";
import {PromiseMethods} from "../../domain/problems/js/PromiseMethods";
import {FloatMethod} from "../../components/FloatMethod/FloatMethod";
import {FunctionMethods} from "../../domain/problems/js/FunctionMethods";
import {GlobalMethods} from "../../domain/problems/js/GlobalMethods";
import {OperatorMethods} from "../../domain/problems/js/OperatorMethods";
import {useSearchParams} from "react-router-dom";
import {IteratorMethods} from "../../domain/problems/js/IteratorMethods";

const methods = [
  ArrayMethods,
  StringMethods,
  MathMethods,
  ObjectMethods,
  RegExpMethods,
  MapMethods,
  SetMethods,
  NumberMethods,
  PromiseMethods,
  FunctionMethods,
  GlobalMethods,
  OperatorMethods,
  IteratorMethods,
].flat();

function useJavascriptMethod() {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedMethodName = searchParams.get("method");
  const selectedMethod = selectedMethodName === null
    ? null
    : methods.find(m => m.method === searchParams.get("method")) ?? null;

  function setSelectedMethod(method: JavascriptMethod) {
    setSearchParams({"method": method.method});
  }

  function removeSelectedMethod() {
    setSearchParams({});
  }

  return {selectedMethod, setSelectedMethod, removeSelectedMethod};
}

export function CheatsheetJavaScript() {
  const {selectedMethod, setSelectedMethod, removeSelectedMethod} = useJavascriptMethod();

  return (
    <div className={s.wrapper}>
      <Helmet>
        <title>
          Методы в JavaScript
        </title>
      </Helmet>
      <h1 className={s.mainTitle}>Список методов в JavaScript</h1>
      <div className={s.preview}>
        <p>Основные классы, которые мы используем при решении задач: Array, String, RegExp, Object, Set, Map, Math,
          Number, Function, Promise.</p>
        <p>Бывают методы, которые изменяют значение, на котором они вызываются. Такие методы называются мутирующими.
          Методы строк, например, всегда
          немутирующие, потому что строка это примитив и ее нельзя изменить.</p>

        <p>😈 означает, что метод мутирующий и с ним нужно быть острожнее, 😇 означает, что метод не мутирующий.</p>

        <p>При клике по заголовку методы сортируются. При клике по строке открывается пример использования и список
          задач, в каких задачах можно использовать метод. Ссылка ведет на MDN.</p>
      </div>

      <FloatMethod method={selectedMethod} onClose={removeSelectedMethod}/>

      <div className={s.tables}>
        <div className={s.table}>
          <h2 className={s.tableHeader}>Методы массивов</h2>
          <MethodsTable methods={ArrayMethods} onClick={setSelectedMethod}/>
        </div>
        <div className={s.table}>
          <h2 className={s.tableHeader}>Методы строк</h2>
          <MethodsTable methods={StringMethods} onClick={setSelectedMethod}/>
        </div>
        <div className={s.table}>
          <h2 className={s.tableHeader}>Методы RegExp</h2>
          <MethodsTable methods={RegExpMethods} onClick={setSelectedMethod}/>
        </div>
        <div className={s.table}>
          <h2 className={s.tableHeader}>Глобальные функции</h2>
          <MethodsTable methods={GlobalMethods} onClick={setSelectedMethod}/>
        </div>
        <div className={s.table}>
          <h2 className={s.tableHeader}>Методы объектов</h2>
          <MethodsTable methods={ObjectMethods} onClick={setSelectedMethod}/>
        </div>
        <div className={s.table}>
          <h2 className={s.tableHeader}>Методы Map</h2>
          <MethodsTable methods={MapMethods} onClick={setSelectedMethod}/>
        </div>
        <div className={s.table}>
          <h2 className={s.tableHeader}>Методы Set</h2>
          <MethodsTable methods={SetMethods} onClick={setSelectedMethod}/>
        </div>
        <div className={s.table}>
          <h2 className={s.tableHeader}>Методы Math</h2>
          <MethodsTable methods={MathMethods} onClick={setSelectedMethod}/>
        </div>
        <div className={s.table}>
          <h2 className={s.tableHeader}>Методы чисел</h2>
          <MethodsTable methods={NumberMethods} onClick={setSelectedMethod}/>
        </div>
        <div className={s.table}>
          <h2 className={s.tableHeader}>Методы функций</h2>
          <MethodsTable methods={FunctionMethods} onClick={setSelectedMethod}/>
        </div>
        <div className={s.table}>
          <h2 className={s.tableHeader}>Методы Promise</h2>
          <MethodsTable methods={PromiseMethods} onClick={setSelectedMethod}/>
        </div>
        <div className={s.table}>
          <h2 className={s.tableHeader}>Операторы и синтаксис</h2>
          <MethodsTable methods={OperatorMethods} onClick={setSelectedMethod}/>
        </div>
        <div className={s.table}>
          <h2 className={s.tableHeader}>Итераторы</h2>
          <MethodsTable methods={IteratorMethods} onClick={setSelectedMethod}/>
        </div>
      </div>
    </div>
  );
}

