import React from "react";
import s from "../CourseDescription/CourseDescription.module.css";
import {CourseBuyProps} from "./CourseBuyProps";
import {Link} from "react-router-dom";
import {fpTopics} from "../../../domain/problems/maxcode/topics/fpTopics";
import {plural, problemsPlural} from "../../../domain/i18n/plural";

export function FpCourseBuy({openDescription}: CourseBuyProps) {
  const problems = fpTopics.flatMap(t => t.problems);
  function handleClick(e: React.MouseEvent) {
    e.preventDefault();
    openDescription();
  }

  return (
    <div className={s.async}>
      <h2>Что вы изучите на практике</h2>

      <p className={s.first}>В подборке {problems.length} {problemsPlural(problems.length)}, {plural(["посвященная", "посвященные", "посвященных"])(problems.length)} фундаментальным концепциями функционального
        программирования</p>

      <p>В этой серии задач мы разбираемся с основными концепциями <b>функционального программирования</b>:
        функции высшего порядка, генераторы, декораторы, карирование и частичное применение.</p>

      <p>В реальной жизни функциональное программирование встречается при работе с самым популярным
        стейт-менеджером <b>Redux</b>. Вам предстоит реализовать полноценную копию библиотеки со всеми методами стора, хелперами и
        поддержкой мидлвар.</p>

      <p>
        Более подробное описание — в разделе со <a href="#" onClick={handleClick}>списком тем</a>.
      </p>

      <p>Практически все задачи можно переписать на <b>TypeScript</b>. Работа с функциями высшего порядка —
        лучший способ разобраться с дженериками (<b>Generic Types</b>) и типизацией в целом. Список задач для изучения тайпскрипта — в разделе <Link to="/roadmaps/typescript">TypeScript Roadmap</Link>.</p>

    </div>
  );
}
