export const prefixSum = [
  {
    section: "Базовые задачи",
    problems: [
      {
        id: 303,
        title: "Range Sum Query - Immutable",
        slug: "range-sum-query-immutable",
        difficulty: "medium"
      },
      {
        id: 304,
        title: "Range Sum Query 2D - Immutable",
        slug: "range-sum-query-2d-immutable",
        difficulty: "medium"
      },
      {
        id: 1314,
        title: "Matrix Block Sum",
        slug: "matrix-block-sum",
        difficulty: "medium"
      },
      {
        id: 724,
        title: "Find Pivot Index",
        slug: "find-pivot-index",
        difficulty: "medium"
      },
      {
        id: 1310,
        title: "XOR Queries of a Subarray",
        slug: "xor-queries-of-a-subarray",
        difficulty: "medium"
      },
      {
        id: 1685,
        title: "Sum of Absolute Differences in a Sorted Array",
        slug: "sum-of-absolute-differences-in-a-sorted-array",
        difficulty: "medium"
      },
    ],
  },
  {
    section: "Дополнительная память",
    problems: [
      {
        id: 523,
        title: "Continuous Subarray Sum",
        slug: "continuous-subarray-sum",
        difficulty: "medium"
      },
      {
        id: 525,
        title: "Contiguous Array",
        slug: "contiguous-array",
        difficulty: "medium"
      },
      {
        id: 560,
        title: "Subarray Sum Equals K",
        slug: "subarray-sum-equals-k",
        difficulty: "medium"
      },
      {
        id: 930,
        title: "Binary Subarrays With Sum",
        slug: "binary-subarrays-with-sum",
        difficulty: "medium"
      },
      {
        id: 974,
        title: "Subarray Sums Divisible by K",
        slug: "subarray-sums-divisible-by-k",
        difficulty: "medium"
      },
      {
        id: 1177,
        title: "Can Make Palindrome from Substring",
        slug: "description",
        difficulty: "medium"
      },
      {
        id: 1371,
        title: "Find the Longest Substring Containing Vowels in Even Counts",
        slug: "find-the-longest-substring-containing-vowels-in-even-counts",
        difficulty: "medium"
      },
      {
        id: 1524,
        title: "Number of Sub-arrays With Odd Sum",
        slug: "number-of-sub-arrays-with-odd-sum",
        difficulty: "medium"
      },
    ],
  },
  {
    section: "Scanning line",
    problems: [
      {
        id: 1854,
        title: "Maximum Population Year",
        slug: "maximum-population-year",
        difficulty: "medium"
      },
      {
        id: 1094,
        title: "Car Pooling",
        slug: "car-pooling",
        difficulty: "medium"
      },
      {
        id: 1109,
        title: "Corporate Flight Bookings",
        slug: "corporate-flight-bookings",
        difficulty: "medium"
      },
      {
        id: 2406,
        title: "Divide Intervals Into Minimum Number of Groups",
        slug: "divide-intervals-into-minimum-number-of-groups",
        difficulty: "medium"
      },
      {
        id: 1893,
        title: "Check if All the Integers in a Range Are Covered",
        slug: "check-if-all-the-integers-in-a-range-are-covered",
        difficulty: "medium"
      },
      {
        id: 452,
        title: "Minimum Number of Arrows to Burst Balloons",
        slug: "minimum-number-of-arrows-to-burst-balloons",
        difficulty: "medium"
      },
      {
        id: 732,
        title: "My Calendar III",
        slug: "my-calendar-iii",
        difficulty: "medium"
      }
    ],
  },
];
