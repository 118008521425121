export const OperatorMethods = [
  {
    method: "Comma operator",
    argument: "a1, a2",
    returnedValue: "a2",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "Conditional operator",
    argument: "condition ? a : b",
    returnedValue: "a или b",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "delete",
    argument: "delete obj.x",
    returnedValue: "",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "Destructuring assignment",
    argument: "[a, b] = [1, 2, 3]",
    returnedValue: "",
    mutable: false,
    nodeVersion: "6.0.0",
    year: 2016,
    tier: "A",
  },
  {
    method: "in",
    argument: "'x' in {x: 5}",
    returnedValue: "boolean",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "instanceof",
    argument: "value instanceof class",
    returnedValue: "boolean",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "Logical AND assignment",
    argument: "x &&= y",
    returnedValue: "",
    mutable: false,
    nodeVersion: "15.0.0",
    year: 2020,
    tier: "A",
  },
  {
    method: "Logical OR assignment",
    argument: "x ||= y",
    returnedValue: "",
    mutable: false,
    nodeVersion: "15.0.0",
    year: 2020,
    tier: "A",
  },
  {
    method: "new",
    argument: "new F()",
    returnedValue: "object",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "new.target",
    argument: "",
    returnedValue: "",
    mutable: false,
    nodeVersion: "5.0.0",
    year: 2015,
    tier: "A",
  },
  {
    method: "Nullish coalescing assignment",
    argument: "x ??= y",
    returnedValue: "",
    mutable: false,
    nodeVersion: "15.0.0",
    year: 2020,
    tier: "A",
  },
  {
    method: "Nullish coalescing operator",
    argument: "x ?? y",
    returnedValue: "",
    mutable: false,
    nodeVersion: "14.0.0",
    year: 2020,
    tier: "A",
  },
  {
    method: "Optional chaining",
    argument: "a?.b",
    returnedValue: "",
    mutable: false,
    nodeVersion: "14.0.0",
    year: 2020,
    tier: "A",
  },
  {
    method: "Remainder",
    argument: "12 % 5 === 2",
    returnedValue: "number",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "Remainder assignment",
    argument: "a %= 3",
    returnedValue: "",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "Spread syntax",
    argument: "...iterable",
    returnedValue: "",
    mutable: false,
    nodeVersion: "5.0.0",
    year: 2015,
    tier: "A",
  },
  {
    method: "super",
    argument: "",
    returnedValue: "",
    mutable: false,
    nodeVersion: "6.0.0",
    year: 2016,
    tier: "A",
  },
  {
    method: "this",
    argument: "",
    returnedValue: "",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "typeof",
    argument: "value",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
] as const;


// for...in
// for...of
// throw
// try...catch

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/rest_parameters

export type OperatorMethod = typeof OperatorMethods[number];

