import s from "./CourseDescription.module.css";
import React from "react";

export function LeetcodeCourseDescription() {
  return (
    <div className={s.fp}>
      <h2>Занятия по алгоритмам</h2>

      <div className={s.access} style={{background: "#ffd793"}}>
        <p>
          На данный момент изучение алгоритмов и структур данных доступно только в формате индивидуальных занятий. На <b>maxcode.dev</b> доступны мои подборки задач LeetCode, на которые мы опираемся при разборе соответствующих тем.
        </p>
      </div>

      <div className="card">
        <h3>Как проходят занятия</h3>
        <p>Мы вместе решаем задачки на какую-то тему: придумываем алгоритм, даем оценку по сложности и по времени, а
          затем программируем. Программирование — самая простая часть. В качестве домашнего задания даются задачки
          примерно на ту же тему, мы обязательно все разбираем на следующем занятии.</p>
      </div>

      <div className="card">
        <h3>На каком языке программирования пишут алгоритмы</h3>
        <p>Лучше писать на том языке, на котором вам привычнее всего. Например, я пишу на джаваскрипте, потому что это
          мой основной язык на протяжении последних 10 лет. Самый популярный вариант это Python. В целом на языках с
          динамической типизацией писать проще. Но можно выбрать Java, C#, C++, Swift, Go или любой язык, который
          поддерживает LeetCode. На занятиях можем использовать любой язык, это не принципиально.</p>
      </div>

      <div className="card">
        <h3>Насколько хорошо надо программировать</h3>
        <p>Нужно уметь программировать и свободно пользоваться стандартной библиотекой. В принципе достаточно умения
          использовать методы массива/списка/вектора и методы объекта/словаря/мэпа.</p>
      </div>

      <div className="card">
        <h3>Сколько нужно решить задач, чтобы научиться</h3>
        <p>На литкоде две тысячи задач. Хорошая новость, что там очень много дублей, то есть задач, которые решаются
          очень похоже. Но все равно придется нарешать несколько сотен задач.</p>
      </div>

      <div className="card">
        <h3>Зачем нужно изучать алгоритмы, если у меня на работе нет таких задач</h3>
        <p>Скорее всего именно поэтому у вас и нет таких задач. Такие задачи уходят людям, которые умеют их решать.
          Давайте тоже учиться.</p>
      </div>

      <div className="card">
        <h3>Сколько это времени займет</h3>
        <p>Зависит от вас, но это месяцы подготовки. Я бы сравнил это с университетским курсом. Студенты, которые учатся
          на программистов, просто в расписании имеют такой курс, который называется «Алгоритмы и структуры данных», в
          базовом формате он занимает 2 семестра, каждую неделю одна лекция и одна практика. Хорошо, что мы не студенты
          и можем заниматься более эффективно.</p>
      </div>

      <div className="card">
        <h3>Сколько стоит занятие</h3>
        <p>Столько же, сколько мое <a href="https://sinyakov.com/frontend">обычное занятие</a> по программированию: 3000&nbsp;₽ за
          60&nbsp;минут. Можно записаться на 90 минут.</p>
      </div>

      <div className="card">
        <h3>Что могу рассказать о себе</h3>
        <p>Я работаю программистом (сейчас в компании Циан) и занимаюсь репетиторством более 10 лет. За последние 5 лет
          помог найти первую работу или устроиться на лучшее место <a href="https://sinyakov.com/frontend/how-long.html">более чем сотне человек</a>. Учился в Санкт-Петербурге в
          ФМЛ 239 и на мат-мехе СПбГУ. С десятками людей успешно готовились к алгоритмическим интервью.</p>
      </div>

      <h2 id="как-со-мной-связаться">Как со мной связаться</h2>

      <div className="main-block">
        <ul className="nonstyle-list">
          <li>Телеграм:&nbsp;<a title="Телеграм" href="https://telegram.me/maxsinyakov">@maxsinyakov</a></li>
          <li>WhatsApp по номеру +79522122526 или скайп maxsinyakov</li>
          <li>В контакте:&nbsp;<a title="Страничка в контакте" href="http://vk.com/maxsinyakov">Максим Синяков</a></li>
          <li>Почта: max<a title="Отправить письмо по почте" href="mailto:max@sinyakov.com">@</a>sinyakov.com</li>
        </ul>
      </div>
    </div>
  );
}
