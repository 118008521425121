export const iteratorsTopics = [
  {
    section: "Работа с данными через Iterator",
    problems: [
      "shortest-word",
      "top-words",
      "find-developers",
      "browsers-list",
      "sort-by-vowels",
      "most-frequent-sum",
      "effective-users",
      "permutations",
      "super-power-frequency",
    ],
  },
  {
    section: "Полифилы методов, принимающих iterable",
    problems: [
      "race",
      "simple-all",
      "all",
      "all-settled",
      "any",
      "object-group-by",
      "map-group-by",
    ],
  },
] as const;
