import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {fetchProblemsThunk} from "./problemsSlice";
import {logout, sendGithubCode, User} from "../api/user";
import {createAsyncThunk} from "../app/hooks";
import {CourseId} from "../domain/problems/courses";

interface State {
  user: User | null,
  courses: {
    courseId: CourseId,
    startedAt: string,
    endedAt: string,
  }[],
  loaded: boolean;
  fallback: boolean,
}

const initialState: State = {
  user: null,
  courses: [],
  loaded: false,
  fallback: false,
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProblemsThunk.fulfilled, (state, action) => {
      const {fallback} = action.payload;
      const {user, courses} = action.payload.response;
      return {
        loaded: true,
        fallback,
        user,
        courses,
      };
    }).addCase(logoutThunk.pending, (state) => {
      return {
        user: null,
        courses: [],
        loaded: true,
        fallback: state.fallback,
      };
    });
  },
});

export const sendGithubCodeThunk = createAsyncThunk(
  'user/postGithubCode',
  async (code: string, thunkAPI) => {
    const user = await sendGithubCode(code);

    thunkAPI.dispatch(userSlice.actions.setUser(user));
    await thunkAPI.dispatch(fetchProblemsThunk());
  },
);

export const logoutThunk = createAsyncThunk(
  'user/logout',
  () => logout(),
);
