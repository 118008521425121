import s from './Breadcrumbs.module.css';
import {Problem} from "../../../api/problems";
import {Link} from "react-router-dom";
import {useSelector} from "../../../app/hooks";
import {useTranslation} from "../../../domain/i18n/i18n";
import {courseId2topics} from "../../../domain/problems/courses";
import {courseIdByProblemSlug} from "../../../domain/problems/maxcode/courseIdByProblemSlug";

interface BreadcrumbsProps {
  problem: Problem,
}

export function Breadcrumbs({problem}: BreadcrumbsProps) {
  const translate = useTranslation();
  const lang = useSelector(state => state.settings.lang);
  const {slug, title, difficulty} = problem;

  const courseId = courseIdByProblemSlug(slug);

  if (courseId === undefined || !Object.hasOwn(courseId2topics, courseId)) {
    return (
      <div className={s.breadcrumbs}>
        <Link className={s.breadcrumbsLink} to={"/roadmaps/javascript"}>{translate("breadcrumbs.problemsList")}</Link><span className={s.topic}> → {title}</span>
        <span className={s.difficulty + " " + difficulty}>{difficulty}</span>
      </div>
    );
  }

  const {topics, title: courseTitle} = courseId2topics[courseId][lang];
  const topicSection = topics
    .find(topic => topic.problems.includes(slug))!
    .section;

  return (
    <div className={s.breadcrumbs}>
      <Link className={s.breadcrumbsLink} to={"/courses/" + courseId}>{courseTitle}</Link><span className={s.topic}> → {topicSection}</span>
      <span className={s.difficulty + " " + difficulty}>{difficulty}</span>
    </div>
  );
}
