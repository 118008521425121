import s from "../CourseDescription/CourseDescription.module.css";
import React from "react";
import {CourseBuyProps} from "./CourseBuyProps";

export function AlgoCourseBuy({openDescription}: CourseBuyProps) {
  function handleClick(e: React.MouseEvent) {
    e.preventDefault();
    openDescription();
  }

  return (
    <div className={s.async}>
      <h2>Какие задачи мы разбираем</h2>

      <p className={s.first}>Это открытый вводный курс по алгоритмам, где мы даем оценки сложности алгоритмов по времени и
        по памяти</p>

      <p>Сначала мы решаем задачу несколькими способами, потом даем теоретическую оценку, а также тестируем, сколько функция работает на практике.</p>

      <p>Краткое описание задач и асимптотическую оценку их сложности можно посмотреть в разделе со <a href="#" onClick={handleClick}>списком тем</a>.</p>

    </div>
  );
}
