import cn from "classnames";
import React from "react";
import {useState} from "react";
import s from "./SubmissionTest.module.css";
import {TestResult} from "../../../api/submissions";

interface SubmissionTestProps {
  test: TestResult,
  id: number,
}

export function SubmissionTest({test, id}: SubmissionTestProps) {
  const {diagnostic, status, description} = test;
  const accepted = status === "OK";

  const [open, setOpen] = useState(false);

  return (
    <div className={cn(
      s.testItem,
      accepted ? s.testAccepted : s.closed,
      open && s.open,
    )}>
      <h3
        className={s.testItemTitle}
        onClick={() => accepted ? undefined : setOpen(p => !p)}
      >
        <b className={s.id}>
          {id.toString().padStart(2, "0")}
        </b>
        <span className={s.description}>
          {description.replace(/^- /, "")}
        </span>
      </h3>
      {!accepted && open && (
        <div className={s.verbose}>
          {diagnostic?.error && (
            <pre className={s.stderrCode}>
              {diagnostic.error}
            </pre>
          )}
        </div>
      )}
    </div>
  );

}
