import {useTranslation} from "../../../domain/i18n/i18n";
import s from "./ProblemSubmissions.module.css";
import {SubmissionTest} from "../SubmissionTest/SubmissionTest";
import {SubmissionResult} from "../../../api/submissions";

interface TestsListProps {
  submissionResult: SubmissionResult,
  code?: string;
}

export function TestsList({submissionResult}: TestsListProps) {
  const translate = useTranslation();

  const {stderr, testResults} = submissionResult;
  const successful = testResults.filter(t => t.status === "OK").length;

  return (
    <div>
      <h1 className={s.testHeader}>
        {translate("submissions.testHeader")}
        <span className={s.testCounter}><span>{successful}</span>/{testResults.length}</span>
      </h1>

      {stderr && (
        <div className={s.stderr}>
          <h3 className={s.stderrTitle}>STDERR:</h3>
          <pre className={s.stderrCode}>
            {stderr}
          </pre>
        </div>
      )}

      <div>
        {testResults.map((test, i) => {
          return (
            <SubmissionTest
              id={i + 1}
              test={test}
            />
          );
        })}
      </div>
    </div>
  );
}
