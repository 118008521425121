export const backtracking = [
  {
    section: "Генерация вариантов",
    problems: [
      {
        title: "39. Combination Sum",
        slug: "combination-sum",
      },
      {
        title: "40. Combination Sum II",
        slug: "combination-sum-ii",
      },
      {
        title: "46. Permutations",
        slug: "permutations",
      },
      {
        title: "47. Permutations II",
        slug: "permutations-ii",
      },
      {
        title: "51. N-Queens",
        slug: "n-queens",
      },
      {
        title: "52. N-Queens II",
        slug: "n-queens-ii",
      },
      {
        title: "77. Combinations",
        slug: "combinations",
      },
      {
        title: "78. Subsets",
        slug: "subsets",
      },
      {
        title: "90. Subsets II",
        slug: "subsets-ii",
      },
      {
        title: "93. Restore IP Addresses",
        slug: "restore-ip-addresses",
      },
    ],
  },
  {
    section: "Поиск решения",
    problems: [
      {
        title: "37. Sudoku Solver",
        slug: "sudoku-solver",
      },
      {
        title: "79. Word Search",
        slug: "word-search",
      },
    ],
  }
];
