import s from "./CheatsheetPromises.module.css";
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";

import {MarkdownRenderer} from "../../components/ProblemPage/ProblemDescription/MarkdownRenderer";

export function CheatsheetPromises() {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch("/static/cheatsheets/promises.md")
      .then(resp => resp.text())
      .then(setContent)
  }, []);

  return (
    <div className={s.wrapper}>
      <Helmet>
        <title>
          Теоретический минимум про промисы
        </title>
      </Helmet>
      <div className={s.content}>
        <MarkdownRenderer>{content}</MarkdownRenderer>
      </div>
    </div>
  );
}

