import React from "react";
import {MaxcodeCourseId} from "../../../domain/problems/MaxcodeCourses";
import {CourseDescriptionProps} from "./CourseDescriptionProps";
import {AsyncCourseDescription} from "./AsyncCourseDescription";
import {FpCourseDescription} from "./FpCourseDescription";
import {RecursionCourseDescription} from "./RecursionCourseDescription";
import {AlgoCourseDescription} from "./AlgoCourseDescription";
import {BitsCourseDescription} from "./BitsCourseDescription";
import {OopCourseDescription} from "./OopCourseDescription";
import {ArraysCourseDescription} from "./ArraysCourseDescription";
import {BasicsCourseDescription} from "./BasicsCourseDescription";
import {ObjectsCourseDescription} from "./ObjectsCourseDescription";
import {ArrayMethodsCourseDescription} from "./ArrayMethodsCourseDescription";
import {IteratorsCourseDescription} from "./IteratorsCourseDescription";

export const CourseDescriptionMap: Record<MaxcodeCourseId, null | React.FunctionComponent<CourseDescriptionProps>> = {
  async: AsyncCourseDescription,
  algo: AlgoCourseDescription,
  fp: FpCourseDescription,
  recursion: RecursionCourseDescription,
  iterators: IteratorsCourseDescription,
  bits: BitsCourseDescription,
  oop: OopCourseDescription,
  arrays: ArraysCourseDescription,
  basics: BasicsCourseDescription,
  objects: ObjectsCourseDescription,
  "array-methods": ArrayMethodsCourseDescription,
};
