import MonacoEditor from '@monaco-editor/react';
import s from "./Editor.module.css"
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "../../../app/hooks";
import {useEffect, useState} from "react";
import {fetchSubmission} from "../../../redux/submissionsSlice";
import cn from "classnames";
import {settingsSlice} from "../../../redux/settingsSlice";
import {useTranslation} from "../../../domain/i18n/i18n";
import {generateGithubAuthUrl} from "../../GithubAuthLink/GithubAuthLink";

interface EditorProps {
  onSendSolution(): void,
}

export function Editor({onSendSolution}: EditorProps) {
  const translate = useTranslation();
  const slug = useParams().slug!;
  const {user, fallback} = useSelector(state => state.user)
  const fontSize = useSelector(state => state.settings.editorFontSize)
  const problem = useSelector(state => state.problems[slug]);
  const lastSubmission = problem.submissionsPreviews.at(-1);
  const isSubmitting = lastSubmission !== undefined && lastSubmission.status === "PENDING";

  const dispatch = useDispatch();

  const [code, setCode] = useState(() => {
    const localCodeMapJson = localStorage.getItem("code");
    const localCodeMap = localCodeMapJson === null ? {} : JSON.parse(localCodeMapJson);
    const localCode = localCodeMap[slug];

    return localCode ?? problem.initialCode;
  });

  useEffect(() => {
    const localCodeMapJson = localStorage.getItem("code");
    const localCodeMap = localCodeMapJson === null ? {} : JSON.parse(localCodeMapJson);

    localCodeMap[slug] = code;
    localStorage.setItem("code", JSON.stringify(localCodeMap));
  }, [code]);

  function handleSendButton() {
    dispatch(fetchSubmission({code, problemId: problem.id, slug}));
    onSendSolution();
  }

  function handleResetSolution() {
    setCode(problem.initialCode);
  }

  const githubAuthUrl = generateGithubAuthUrl();

  return (
    <div>
      <div className={s.top}>
        <div className={cn(s.inner, s.editorTop)}>
          <h2 className={s.editorTitle}>{translate("problem.yourSolution")}</h2>
          <div className={s.editorSetting}>
            <div className={s.fontSize}>
              <button
                className={s.fontSizeButton}
                type="button"
                onClick={() => dispatch(settingsSlice.actions.decreaseEditorFontSize())}
                disabled={fontSize === 10}
              >
                −
              </button>
              <span className={s.fontSizeText}>
                {fontSize}px
              </span>
              <button
                className={s.fontSizeButton}
                type="button"
                onClick={() => dispatch(settingsSlice.actions.increaseEditorFontSize())}
                disabled={fontSize === 32}
              >
                +
              </button>
            </div>
            <button
              className={s.fullSizeButton}
              type="button"
              onClick={() => dispatch(settingsSlice.actions.toggleEditorFullSize())}
            >
              fullsize
            </button>
          </div>
        </div>
        <div className={s.editor}>
          <MonacoEditor
            className={s.code}
            defaultLanguage="javascript"
            value={code}
            onChange={setCode}
            options={{
              theme: "One Light",
              fontFamily: "Fira Code",
              minimap: {enabled: false},
              scrollbar: {verticalScrollbarSize: 0},
              wordWrap: "on",
              lineNumbers: "off",
              folding: false,
              tabSize: 2,
              fontSize,
            }}
          />
        </div>
        <div className={s.bottom}>
          <button
            className={s.resetButton}
            onClick={handleResetSolution}
          >
            {translate("problem.reset")}
          </button>

          {!fallback && (user === null ? (
            <a
              className={cn(s.sendButton, s.needAuth)}
              href={githubAuthUrl}
            >
              {translate("problem.send")}
            </a>
          ) : (
            <button
              className={s.sendButton}
              onClick={handleSendButton}
              disabled={isSubmitting}
            >
              {translate("problem.send")}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
