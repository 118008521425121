import React, {Fragment} from "react";
import {OneColumnLayout} from "../../components/Layout/OneColumnLayout";
import {CoursesPageContent} from "../../components/CoursePage/CoursesPageContent/CoursesPageContent";
import {Helmet} from "react-helmet-async";

export function HomePage() {

  return (
    <Fragment>
      <Helmet>
        <title>Все курсы от maxcode.dev</title>
      </Helmet>
      <OneColumnLayout content={<CoursesPageContent />} />
    </Fragment>
  );
}
