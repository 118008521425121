import s from "./CourseDescription.module.css";
import {Link} from "react-router-dom";
import React from "react";

export function RecursionCourseDescription() {
  return (
    <div className={s.fp}>
      <h2>Какие темы разбираются в курсе</h2>

      <h3>Basics</h3>
      <p>Сначала мы разбираем задачи, где достаточно знать основной принцип рекурсии — разделяй и властвуй, а базовый случай рекурсии тривиален.</p>

      <h3>Extra Argument</h3>
      <p>Изучаем один из популярных подходов при решении задач на рекурсию — использование дополнительного аргумента.</p>

      <h3>Base Case</h3>
      <p>Разбираем несколько задач, где условие выхода не так очевидно.</p>

      <h3>Nested Categories</h3>
      <p>Серия прикладных задач про обход дерева категорий. По айдишнику категории нужно находить дочерние категории, родительскую категорию, а также собирать «хлебные крошки».</p>

      <h3>Islands</h3>
      <p>Классические задачи про острова, знакомящие по сути с принципом обхода в глубину. Больше задач — в курсе про <Link to="/courses/dfs">DFS</Link>.</p>

      <h3>Backtracking</h3>
      <p>Решаем судоку, расставляем ферзей, ищем слово в таблице и генерируем перестановки. Подробнее — в курсе про <Link to="/courses/backtracking">бэктрекинг</Link>.</p>

      {/*<h3>Cache & Dynamic Programming</h3>*/}
      {/*<p>Рассмотрим задачи, демонстрирующие важность кэширования при использовании рекурсии. На примере буквально пары задач разберем тему <Link to="/courses/dynamic">динамического программирования</Link>.</p>*/}
    </div>
  );
}
