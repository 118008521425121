import s from "./CourseDescription.module.css";
import {MethodsTable} from "../../MethodsTable/MethodsTable";
import React from "react";
import {IteratorMethods} from "../../../domain/problems/js/IteratorMethods";
import {YouTubePlayer} from "../../ProblemPage/ProblemSolution/ProblemSolution";
import {useNavigate} from "react-router-dom";
import {JavascriptMethods} from "../../../domain/problems/js/JavascriptMethods";

const methods = JavascriptMethods
  .filter(({method, returnedValue}) => returnedValue === "iterator" && !method.startsWith("Iterator"));

export function IteratorsCourseDescription() {
  const navigate = useNavigate();

  function openJavascriptCheatsheets(method: string) {
    const url = `/cheatsheets/javascript?method=${encodeURIComponent(method)}`;
    navigate(url);
  }


  return (
    <div className={s.fp}>
      <h2>Теория про итераторы</h2>
      <YouTubePlayer youtubeId="4mUZL6zXBH4"/>

      <h3>Как получить итератор</h3>
      <MethodsTable
        methods={methods}
        onClick={({method}) => openJavascriptCheatsheets(method)}
        fullMethodName
      />

      <h3>Методы итераторов</h3>
      <MethodsTable
        methods={IteratorMethods}
        onClick={({method}) => openJavascriptCheatsheets(method)}
        fullMethodName
      />
    </div>
  );
}
