export const plural = ([one, few, many]: [string, string, string]) => (num: number) => {
  if (num % 10 === 1 && num !== 11) {
    return one;
  }

  if (num > 10 && num < 15) {
    return many;
  }

  if (num % 10 === 2 || num % 10 === 3 || num % 10 === 4) {
    return few;
  }

  return many;
};
export const problemsPlural = plural(["задача", "задачи", "задач"]);
