import { useDispatch, useSelector } from "../../../app/hooks";
import s from "./SubmissionsHistory.module.css";
import { SubmissionStatusBadge } from "./SubmissionStatusBadge";
import { formatDateTime } from "../../../domain/formatDateTime";
import {cancelSubmissionsThunk, FAKE_TOKEN} from "../../../redux/submissionsSlice";

interface SubmissionsHistoryProps {
  problemSlug: string;
  onSelect: (token: string) => void;
}

export function SubmissionsHistory({
  problemSlug,
  onSelect,
}: SubmissionsHistoryProps) {
  const dispatch = useDispatch();
  const submissionsPreviews = useSelector(state => state
    .problems[problemSlug]
    .submissionsPreviews
    .map(s => ({ ...s, submittedAt: new Date(s.submittedAt) }))
    .sort((a, b) => +b.submittedAt - +a.submittedAt)
  );

  return (
    <div className={s.wrapper}>
      <h2>История отправок</h2>
      <div className={s.list}>
        {submissionsPreviews.map(preview => {
          return (
            <div className={s.line} key={preview.token} onClick={() => onSelect(preview.token)}>
              <span className={s.date}>{formatDateTime(preview.submittedAt)}</span>
              <SubmissionStatusBadge status={preview.status} />
              {preview.status === "PENDING" && preview.token !== FAKE_TOKEN && (
                <button
                  className={s.cancel}
                  onClick={() => dispatch(cancelSubmissionsThunk({
                    submissionId: preview.token,
                    slug: problemSlug,
                  }))}
                >
                  Отменить
                </button>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}
