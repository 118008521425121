import {Link} from "react-router-dom";
import screen from "../../pages/AsyncCourseLanding/screen.png";
import s from "./MaxcodePromo.module.css";
import cn from "classnames";


export function MaxcodePromo() {
  return (
    <div className={s.bottom}>
      <div className={s.wrapper}>
        <div className={s.bottomContent}>
          <div className={s.bottomLeft}>
            <div className={s.about}>
              <p><strong>MaxCode.dev</strong> дает конкретный ответ на&nbsp;вопрос, что нужно делать на практике, чтобы
                понять определенную тему по&nbsp;джаваскрипту и&nbsp;как определить, что ты&nbsp;с&nbsp;этой темой
                разобрался</p>
              {/*<p>На&nbsp;каждую тему представлено 20&#8209;30 задач с&nbsp;описанием, тестами и&nbsp;видеоразборами на&nbsp;русском языке. Все задачи подобраны так, чтобы на&nbsp;практических примерах объяснить теорию.</p>*/}
              {/*<p>Рассмотрены все продвинутые темы: рекурсия, функциональное и&nbsp;объектно-ориентированное программирование, а&nbsp;также асинхронность. С&nbsp;примерами на&nbsp;джаваскрипте разбираются алгоритмические задачи с&nbsp;оценкой сложности через&nbsp;O большое.</p>*/}
              {/*<p>Сами задачи выстроены в&nbsp;роадмап (план обучения). Таким образом сайт можно использовать для изучения джаваскрипта с&nbsp;нуля, а&nbsp;также для подготовки к&nbsp;собеседованиям.</p>*/}

              <div className={s.points}>
                <div className={cn(s.point, "roadmap")}>
                  <h3>План обучения</h3>
                  <p>Более 150 задач разбиты по темам и выстроены в практический роадмап</p>
                </div>

                <div className={cn(s.point, "ru")}>
                  <h3>Материалы на русском</h3>
                  <p>Условия задач, тесты и разборы решений доступны на русском языке</p>
                </div>

                <div className={cn(s.point, "refSolution")}>
                  <h3>Авторские решения</h3>
                  <p>После успешной отправки задачи открывается авторское решение, написанное в продакшен-стиле</p>
                </div>

                <div className={cn(s.point, "node")}>
                  <h3>Node.js 22</h3>
                  <p>Тестирующая система использует самую последнюю версию Node.js с новыми методами JavaScript</p>
                </div>
              </div>
            </div>
          </div>
          <div className={s.bottomRight}>
            <Link className={s.demoLink} to="/problems/all">
              <img alt="Пример задачи" src={screen} className={s.screenImage}/>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
