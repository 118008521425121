import Markdown from "react-markdown";
import {Link} from "react-router-dom";
import s from "./ProblemDescription.module.css";
import {Prism} from "react-syntax-highlighter";
import light from "react-syntax-highlighter/dist/cjs/styles/prism/one-light";
import cn from "classnames";

interface MarkdownRendererProps {
  children: string;
}

export function MarkdownRenderer({children}: MarkdownRendererProps) {
  return (
    <Markdown
      children={children}
      components={{
        a(props) {
          if (props.href === undefined || props.href.startsWith("http")) {
            return <a href={props.href} target="_blank">{props.children}</a>;
          }
          return <Link to={props.href}>{props.children}</Link>;
        },
        img(props) {
          return (
            <img
              src={props.src}
              className={s.image}
            />
          );
        },
        code(props) {
          const {children, className, node, ...rest} = props
          const match = /language-(\w+)/.exec(className || '')

          return match ? (
            <div className={s.jsCode}>
              <button
                className={s.copyCode}
                onClick={() => navigator.clipboard.writeText(String(children))}
              >
                copy
              </button>
              <Prism
                children={String(children).replace(/\n$/, '')}
                style={light}
                language={"javascript"}
                PreTag="div"
              />
            </div>
          ) : (
            <code {...rest} className={cn(s.code, className)}>
              {children}
            </code>
          )
        }
      }}
    />
  );
}
