import {MaxcodeCourseId} from "../../../domain/problems/MaxcodeCourses";
import React from "react";
import {CourseBuyProps} from "./CourseBuyProps";
import {AsyncCourseBuy} from "./AsyncCourseBuy";
import {AlgoCourseBuy} from "./AlgoCourseBuy";
import {FpCourseBuy} from "./FpCourseBuy";

export const CourseBuyMap: Record<MaxcodeCourseId, null | React.FunctionComponent<CourseBuyProps>> = {
  async: AsyncCourseBuy,
  algo: AlgoCourseBuy,
  fp: FpCourseBuy,
  oop: null,
  recursion: null,
  iterators: null,
  bits: null,
  arrays: null,
  basics: null,
  objects: null,
  "array-methods": null,
};
