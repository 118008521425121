import {Settings} from "../../redux/settingsSlice";
import {LeetcodeCourses} from "./leetcode/LeetcodeCourses";
import {MaxcodeCourses} from "./MaxcodeCourses";

export const RuCourses = [
  ...MaxcodeCourses,
  ...LeetcodeCourses,
];

export type RuCourse = typeof RuCourses[number];
export const COURSES_IDS = RuCourses.map(x => x.id);
export type CourseId = typeof COURSES_IDS[number];

export type CourseDescription = {
  title: CourseId,
  topics: {
    section: string,
    problems: string[],
  }[],
};

export const courseId2topics = Object.fromEntries(
  RuCourses.map(({topics, id, title}) => {
    return [
      id,
      {
        ru: {
          title,
          topics,
        },
        en: {
          title,
          topics,
        },
      }
    ]
  })
) as unknown as Record<CourseId, Record<Settings["lang"], CourseDescription>>;
