import {Problem} from "../../../api/problems";
import s from "./ProblemSolution.module.css";
import {courseIdByProblemSlug} from "../../../domain/problems/maxcode/courseIdByProblemSlug";
import {Link} from "react-router-dom";
import {courseId2topics} from "../../../domain/problems/courses";
import cn from "classnames";
import {isSellableCourses, Payment} from "../../Payment/Payment";
import {MarkdownRenderer} from "../ProblemDescription/MarkdownRenderer";
import {AsyncLandingPromoLink} from "../../CoursePage/CoursesPageContent/CoursesPageContent";
import {useSelector} from "../../../app/hooks";

interface ProblemSolutionProps {
  problem: Problem;
}

export function YouTubePlayer({ youtubeId }: { youtubeId: string }) {
  return (
    <iframe
      className={s.youtube}
      src={"https://www.youtube.com/embed/" + youtubeId}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    />
  );
}

export function ProblemSolution({problem}: ProblemSolutionProps) {
  const {youtubeId, referenceSolution, access} = problem;
  const referenceSolutionMarkdown = `~~~js\n${referenceSolution}\n~~~`;
  const courseId = courseIdByProblemSlug(problem.slug)!;
  const courses = useSelector(s => s.user.courses);

  if (access.includes("SOLUTION")) {
    const boughtCourse = courses.some(x => x.courseId === courseId + "-pro");
    const done = problem !== undefined && problem.submissionsPreviews.some(sp => sp.status === "OK");

    if (!done && !boughtCourse) {
      return (
        <div>
          <h1>Авторское решение</h1>
          <p>Решение доступно после успешной отправки вашего решения</p>
          {/*<div className={s.maxcodepro}>*/}
          {/*  <p>Оплатите подписку, чтобы получить доступ к авторскому решению без отправки своего решения.</p>*/}
          {/*  <p>Подписка дает доступ к видео-разбору, а также к подказкам и неограниченному количеству отправок</p>*/}
          {/*</div>*/}
        </div>
      );
    }

    return (
      <div>
        <h1>Авторское решение</h1>
        {youtubeId && (
          <YouTubePlayer youtubeId={youtubeId} />
        )}
        <MarkdownRenderer>
          {referenceSolutionMarkdown}
        </MarkdownRenderer>
      </div>
    );
  }

  if (isSellableCourses(courseId)) {
    return (
      <div>
        <h1>Авторское решение</h1>
        <div>
          <p>Доступно при покупке курса <Link
            className={cn(s.course, courseId)}
            to={"/promo/" + courseId}
          >
            {courseId2topics[courseId].ru.title}
          </Link>.
          </p>
          {courseId === "async" && (
            <AsyncLandingPromoLink />
          )}
        </div>
      </div>
    );
  }

  return null;
}
