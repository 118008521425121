export const asyncTopics = [
  {
    section: "Разминка",
    problems: [
      "add-two-promises",
      "add-all-promises",
      "promise-logic",
      "count-fulfilled-promises",
    ],
  },
  {
    section: "Статические методы промисов",
    problems: [
      "race",
      "simple-all",
      "all",
      "all-settled",
      "any",

      // "promise-resolve",
      // "promise-reject",
      // "promise-try",
      // "with-resolvers",
    ],
  },
  {
    section: "Микротаски и макротаски",
    problems: [
      "sleep",
      "microtasks",
      "event-loop",
      // "raf",
      // "cancelable-promise",
      // "with-fallback",
      "time-limit",
      "state",
    ],
  },
  {
    section: "Методы промисов",
    problems: [
      "chain",
      "then",
      "catch",
      "finally",
    ],
  },
  {
    section: "Запуск асинхронных функций",
    problems: [
      "compose-async",
      "polling",
      "promise-batch",
      "retry",
      "promise-pool",
      "promise-pool-memo",
    ],
  },
  {
    section: "Async/await",
    problems: [
      "async-try-catch",
      "async-event-loop",
      "boost-async-flatten",
    ],
  },
  {
    section: "Callbacks",
    problems: [
      "promisify",
      "callbackify",
      "compose-callback",
      // "parallel-callback",
    ],
  },
  {
    section: "Рекурсивные структуры",
    problems: [
      "file-system",
      "products",
    ],
  },
  // {
  //   section: "Реализуем Promise",
  //   problems: [
  //     "promise-diy",
  //     "async-await-diy",
  //   ],
  // },
  {
    section: "Таймеры",
    problems: [
      "cache",
      "debounce",
      "throttle",
    ],
  },
] as const;
