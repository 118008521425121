import React from "react";
import cn from "classnames";
import s from "./CoursesList.module.css";
import {MaxcodeCourse} from "../../domain/problems/MaxcodeCourses";
import {LeetcodeCourse} from "../../domain/problems/leetcode/LeetcodeCourses";
import {CoursePromoLink} from "./CoursePromoLink";


interface CoursesListProps {
  courses: readonly MaxcodeCourse[] | readonly LeetcodeCourse[];
  mini?: boolean;
}

export function CoursesList({
  courses,
  mini = false,
}: CoursesListProps) {
  return (
    <div className={cn(mini && s.mini)}>
      <div className={s.list}>
        {courses
          .map((course) => {
            return (
              <div className={s.course} key={course.id}>
                <CoursePromoLink course={course} />
              </div>
            );
          })}
      </div>
    </div>
  );
}

