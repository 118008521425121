import {useEffect} from "react";
import {useDispatch, useSelector} from "../../app/hooks";
import {fetchUserSubmissionsThunk} from "../../redux/submissionsSlice";
import s from "./SubmissionsHistory.module.css"
import {Link} from "react-router-dom";
import cn from "classnames";
import {courseIdByProblemSlug} from "../../domain/problems/maxcode/courseIdByProblemSlug";
import {SubmissionStatusBadge} from "../ProblemPage/SubmissionsHistory/SubmissionStatusBadge";
import {formatDateTime} from "../../domain/formatDateTime";
import {MarkdownRenderer} from "../ProblemPage/ProblemDescription/MarkdownRenderer";

export function SubmissionsHistory() {
  const dispatch = useDispatch();
  const submissions = useSelector(s => s.submissions.submissionsHistory);
  const problemsMap = useSelector(s => s.problems)

  useEffect(() => {
    dispatch(fetchUserSubmissionsThunk(0));
  }, []);

  return (
    <div className={s.wrapper}>
      <h1 className={s.mainHeader}>Последние отправки</h1>
      <div>
        {submissions.map(({
          problemSlug,
          code,
          status,
          submittedAt
        }) => {
          const problem = problemsMap[problemSlug];
          const courseId = courseIdByProblemSlug(problemSlug);
          const submittedAtDate = new Date(submittedAt);
          const solutionMarkdown = `~~~js\n${code}\n~~~`;

          return (
            <div className={s.submission}>
              <header className={s.header}>
                <Link to={"/problems/" + problem.slug} className={cn(s.link, courseId)}>
                  {problem.title}
                </Link>
                <div className={s.right}>
                  {formatDateTime(submittedAtDate)}
                  <SubmissionStatusBadge status={status} />
                </div>
              </header>
              <MarkdownRenderer children={solutionMarkdown}/>
            </div>
          );
        })}
      </div>
    </div>
  );
}
