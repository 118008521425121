export const fpTopics = [
  {
    section: "Методы массивов",
    problems: [
      "filter",
      "for-each",
      // "some",
      // "every",
      "map",
      "zip",
      "reduce",
      "sort",
    ],
  },
  {
    section: "Функции высшего порядка",
    problems: [
      "find-integer",
      "plural",
    ],
  },
  {
    section: "Группировка",
    problems: [
      "group-by-equality",
      "map-group-by",
      "map-group-by-advanced",
      "super-power-frequency",
    ],
  },
  {
    section: "Генераторы значений",
    problems: [
      "repeater",
      "fibonacci-generator",
      "prime-generator",
    ],
  },
  {
    section: "Функции-декораторы",
    problems: [
      "multi-predicate",
      "compose",
      "once",
      "memo",
      "spy",
      "args-validator",
      "golang-errors",
      "named-args",
      "memo-advanced",
    ],
  },
  {
    section: "Карирование",
    problems: [
      "select",
      "string-joining",
      "sum",
      "partial-application",
      "currying",
      "uncurry",
    ],
  },
  {
    section: "Unit-тесты",
    problems: [
      "expect-to-be",
      "expect-to-be-close-to",
      "expect-to-throw",
    ],
  },
  {
    section: "Реализуем Redux",
    problems: [
      "create-store",
      "combine-reducers",
      "middlewares",
      "create-slice",
      "create-async-thunk",
    ],
  },
  // {
  //   section: "Redux Toolkit",
  //   problems: [
  //     "create-listener-middleware",
  //     "create-entity-adapter",
  //   ],
  // },
] as const;
