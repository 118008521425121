export const IteratorMethods = [
  {
    method: "Iterator#map",
    argument: "mapper",
    returnedValue: "iterator",
    mutable: false,
    nodeVersion: "22.0.0",
    year: 2024,
    tier: "A",
    example: `
      ["aa", "b"]
        .values()
        .map(x => x.length)
        .toArray() === [2, 1]
    `,
  },
  {
    method: "Iterator#flatMap",
    argument: "mapper",
    returnedValue: "iterator",
    mutable: false,
    nodeVersion: "22.0.0",
    year: 2024,
    tier: "A",
    example: `
      ["abc", "qwe"]
        .values()
        .flatMap(word => word.split(""))
        .toArray() ===      
      ["a", "b", "c", "q", "w", "e"]
    `,
  },
  {
    method: "Iterator#filter",
    argument: "predicate",
    returnedValue: "iterator",
    mutable: false,
    nodeVersion: "22.0.0",
    year: 2024,
    tier: "A",
    example: `
      [1, -5, 8, -2, 12]
        .values()
        .filter(x => x > 0)
        .toArray() === [1, 8, 12]
    `,
  },
  {
    method: "Iterator#take",
    argument: "index",
    returnedValue: "iterator",
    mutable: false,
    nodeVersion: "22.0.0",
    year: 2024,
    tier: "A",
    example: `
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
        .values()
        .take(3)
        .toArray() === [0, 1, 2]
    `,
  },
  {
    method: "Iterator#drop",
    argument: "index",
    returnedValue: "iterator",
    mutable: false,
    nodeVersion: "22.0.0",
    year: 2024,
    tier: "A",
    example: `
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
        .values()
        .drop(7)
        .toArray() === [7, 8, 9]
    `,
  },

  {
    method: "Iterator#every",
    argument: "predicate",
    returnedValue: "boolean",
    mutable: false,
    nodeVersion: "22.0.0",
    year: 2024,
    tier: "A",
    example: `
      const it = [1, 5, 8, 2, 12].values();
      
      it.every(x => x > 0) === true
      it.every(x => x % 2 === 0) === false
    `,
  },
  {
    method: "Iterator#some",
    argument: "predicate",
    returnedValue: "boolean",
    mutable: false,
    nodeVersion: "22.0.0",
    year: 2024,
    tier: "A",
    example: `
      const it = [1, 5, 8, 2, 12].values();
      
      it.some(x => x < 0) === false
      it.some(x => x % 2 === 0) === true
    `,
  },
  {
    method: "Iterator#find",
    argument: "predicate",
    returnedValue: "value",
    mutable: false,
    nodeVersion: "22.0.0",
    year: 2024,
    tier: "A",
    example: `
      const it = [1, 5, 8, 2, 12].values();
      
      it.find(x => x > 6) === 8
      it.find(x => x > 60) === undefined
    `,
  },
  {
    method: "Iterator#forEach",
    argument: "callback",
    returnedValue: "void",
    mutable: false,
    nodeVersion: "22.0.0",
    year: 2024,
    tier: "A",
  },
  {
    method: "Iterator#reduce",
    argument: "callback",
    returnedValue: "accumulator",
    mutable: false,
    nodeVersion: "22.0.0",
    year: 2024,
    tier: "A",
    example: `
      const it = [{x: 2}, {x: 3}, {x: 4}].values();

      it.reduce((acc, elem) => acc * elem.x, 1)
      === 24  
    `,
  },
  {
    method: "Iterator#toArray",
    argument: "—",
    returnedValue: "array",
    mutable: false,
    nodeVersion: "22.0.0",
    year: 2024,
    tier: "A",
    example: `
      const it = [1, 5, 8, 2, 12].values();
      
      it.toArray() === [1, 5, 8, 2, 12]
    `,
  },

  {
    method: "Iterator.from",
    argument: "iterable",
    returnedValue: "iterator",
    mutable: false,
    nodeVersion: "22.0.0",
    year: 2024,
    tier: "A",
    example: `
      Iterator.from("qwe").toArray() === ["q", "w", "e"]
    `,
  },
] as const;

export type IteratorMethod = typeof IteratorMethods[number];

