import React from "react";
import {Link} from "react-router-dom";
import cn from "classnames";
import {useSelector} from "../../../app/hooks";
import s from "./CourseProblemsList.module.css";
import {CourseId, courseId2topics} from "../../../domain/problems/courses";
import {Problem} from "../../../api/problems";
import {courseIdByProblemSlug} from "../../../domain/problems/maxcode/courseIdByProblemSlug";

interface ProblemsListProps {
  courseId: CourseId;
}

export function CourseProblemsList({courseId}: ProblemsListProps) {
  const lang = useSelector(state => state.settings.lang);
  const {topics} = courseId2topics[courseId][lang];

  return (
    <ol className={s.contents}>
      {topics.map(({section, problems}) => {
        return (
          <li key={section}>
            <span className={s.section}>{section}</span>
            <div className={s.sectionLinks}>
              {problems.map(slug => <ProblemLink key={slug} slug={slug} />)}
            </div>
          </li>
        )
      })}
    </ol>
  );
}

interface ProblemLinkProps {
  slug: string;
  white?: boolean;
}

export function ProblemLink({ slug, white }: ProblemLinkProps) {
  const problemsMap = useSelector(state => state.problems);

  const problem = problemsMap[slug] as Problem | undefined;
  const locked = problem === undefined || !problem.access.includes("DESCRIPTION");
  const solved = problem !== undefined && problem.submissionsPreviews.some(sp => sp.status === "OK");

  return (
    <div className={s.problemLinkWrapper} key={slug}>
      <Link
        to={"/problems/" + slug}
        className={cn(
          s.problem,
          solved && s.solved,
          problem && problem.difficulty,
          locked && s.disabled,
          // problem && courseIdByProblemSlug(problem.slug),
          white && s.white,
        )}
      >
        <span className={s.problemLink}>{slug}</span>
      </Link>
    </div>
  );
}
