import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {fetchProblems, Problem, SubmissionPreview} from "../api/problems";
import {createAsyncThunk} from "../app/hooks";
import {MainCourses} from "../domain/problems/MaxcodeCourses";
import {logoutThunk} from "./userSlice";
import { cancelSubmissionsThunk } from "./submissionsSlice";

export const problemsSlice = createSlice({
  name: "problems",
  reducers: {
    addSubmission(state, action: PayloadAction<{
      slug: string,
      token: string,
      submissionPreview: SubmissionPreview,
    }>) {
      const {slug, token, submissionPreview} = action.payload;
      const submissionPreviewIndex = state[slug].submissionsPreviews.findIndex(s => s.token === token);
      if (submissionPreviewIndex === -1) {
        state[slug].submissionsPreviews.push(submissionPreview);
      } else {
        state[slug].submissionsPreviews[submissionPreviewIndex] = submissionPreview;
      }
    },
  },
  initialState: {} as Record<string, Problem>,
  extraReducers: (builder) => {
    builder
      .addCase(fetchProblemsThunk.fulfilled, (state, action) => {
        return Object.fromEntries(action.payload.response.problems.map(p => [p.slug, p]));
      })
      .addCase(cancelSubmissionsThunk.pending, (state, action) => {
        const { slug, submissionId } = action.meta.arg;
        const submissionPreviewIndex = state[slug].submissionsPreviews.findIndex(s => s.token === submissionId);
        if (submissionPreviewIndex !== -1) {
          state[slug].submissionsPreviews[submissionPreviewIndex].status = "CANCELED";
        }
  
        return state;
      })
      .addCase(logoutThunk.pending, (state) => {
        for (const key in state) {
          state[key].submissionsPreviews = [];
        }
      });
  },
});

export const fetchProblemsThunk = createAsyncThunk(
  'problems/fetchProblems',
  (_, thunkAPI) => {
    const {settings} = thunkAPI.getState();
    const problemsIds = MainCourses
      .flatMap(x => x.topics.flatMap(t => t.problems));
    return fetchProblems(problemsIds, settings.lang);
  });
