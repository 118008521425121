import {User} from "./user";
import {CourseId} from "../domain/problems/courses";
import {SubmissionStatus} from "./submissions";

export interface SubmissionPreview {
  token: string
  problemSlug: string
  problemId: number,
  submittedAt: string,
  status: SubmissionStatus,
}

export interface SubmissionPreviewWithCode extends SubmissionPreview {
  code: string;
}

export interface Problem {
  id: number;
  slug: string;
  title: string;
  difficulty: string,
  description: string | null,
  initialCode: string | null,
  referenceSolution: string | null,
  submissionsPreviews: SubmissionPreview[],
  youtubeId: string | null,
  access: ("DESCRIPTION" | "SUBMISSION" | "SOLUTION")[],
}

export interface Payment {
  id: number;
  yookassaPaymentId: string;
  amount: number;
  discountedAmount: number;
  status: string;
  userId: string;
  courseId: string;
  promocode: string;
  createdAt: string;
  updatedAt: string;
}

interface ProblemsResponse {
  problems: Problem[],
  user: User,
  courses: {
    courseId: CourseId,
    startedAt: string,
    endedAt: string,
  }[],
  payments: Payment[]
}

export function fetchProblems(ids: string[], lang: string): Promise<{ response: ProblemsResponse, fallback: boolean }> {
  const search = new URLSearchParams(
    ids.map(id => ['problems', id])
  );
  search.append("lang", lang);

  return fetch("/api/v1/problems?" + search).then(r => {
    if(r.status.toString().startsWith("5")) {
      throw "500";
    }
    return r.json().then(response => ({ response, fallback: false }));
  }).catch(() => {
    return fetch("/static/fallback/problems-24-08-12.json")
      .then(r => r.json())
      .then(response => ({ response, fallback: true }));
  })
}
