import s from "./CourseMainContent.module.css";
import {TabsSwitcher} from "../../TabsSwitcher/TabsSwitcher";
import React, {useState} from "react";
import {CourseId} from "../../../domain/problems/courses";
import {useTranslation} from "../../../domain/i18n/i18n";
import {isLeetcodeCourse} from "../../../domain/problems/leetcode/LeetcodeCourses";
import {CourseDescriptionMap} from "../CourseDescription/CourseDescriptionMap";
import {CourseBuyMap} from "../CourseBuy/CourseBuyMap";
import {LeetcodeCourseDescription} from "../CourseDescription/LeetcodeCourseDescription";

const COURSE_DESCRIPTION_VIEWS = [
  "buy",
  "courseDescription",
  // "theory",
] as const;

export type CourseView = typeof COURSE_DESCRIPTION_VIEWS[number];

interface CourseMainContentProps {
  courseId: CourseId;
}

export function CourseMainContent({courseId}: CourseMainContentProps) {
  const courseDescription = isLeetcodeCourse(courseId)
    ? null
    : CourseDescriptionMap[courseId] && React.createElement(CourseDescriptionMap[courseId]!, {});

  const buyProps = {
    openDescription: () => setView("courseDescription")
  }

  const buy = isLeetcodeCourse(courseId)
    ? <LeetcodeCourseDescription/>
    : CourseBuyMap[courseId] && React.createElement(CourseBuyMap[courseId]!, buyProps);

  const view2component: Record<CourseView, React.ReactNode> = {
    buy,
    courseDescription,
  };

  const entries = Object.entries(view2component)
    .filter(x => x[1] !== null);
  const view2componentFiltered = Object.fromEntries(entries);

  const actualViews = COURSE_DESCRIPTION_VIEWS
    .filter(tab => tab in view2componentFiltered)

  const [view, setView] = useState<CourseView>(actualViews[0]);
  const translate = useTranslation();

  if (actualViews.length === 0) {
    return null;
  }

  return (
    <div className={s.wrapper}>
      <div className={s.content}>
        <div className={s.contentInner}>
          {view2componentFiltered[view]}
        </div>
      </div>
      <div className={s.tabs}>
        <TabsSwitcher
          labels={actualViews.map(tab => ({tab, label: translate(`tab.${tab}`)}))}
          view={view}
          setView={setView}
        />
      </div>
    </div>
  );
}
