import React, {useEffect} from "react";
import {MainHeader} from "./MainHeader";
import s from './OneColumnLayout.module.css';
import {useSelector} from "../../app/hooks";

interface LayoutProps {
  content: React.ReactNode,
}

export function OneColumnLayout({
  content,
}: LayoutProps) {
  useEffect(() => {
    window.scrollTo(0, 0)
  });

  const fallback = useSelector(s => s.user.fallback)

  return (
    <div>
      <MainHeader/>
      {fallback && (
        <div className={s.fallback}>
          Сайт временно доступен в режиме только для чтения
        </div>
      )}
      <div className={s.oneColumn}>
        {content}
      </div>
      <div className={s.footer}>
        Синяков Максим Алексеевич | ИНН 780108986188<br/>
        Почта: max@sinyakov.com, телеграм: @maxsinyakov
      </div>
    </div>
  )
}
