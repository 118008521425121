import s from "./CourseDescription.module.css";

import React from "react";
import {MethodsTable} from "../../MethodsTable/MethodsTable";
import {ArrayMethods} from "../../../domain/problems/js/ArrayMethods";
import {plural} from "../../../domain/i18n/plural";
import {Link} from "react-router-dom";

export function ArrayMethodsCourseDescription() {
  const staticMethods = ArrayMethods.filter(m => m.method.includes("."));
  const instanceMethods = ArrayMethods.filter(m => m.method.includes("#"));
  return (
    <div className={s.fp}>
      <h2>Зачем писать стандартные методы</h2>
      <p>У массивов в джаваскрипте {ArrayMethods.length} {plural(["метод", "метода", "методов"])(ArrayMethods.length)} ({instanceMethods.length} {plural(["обычный", "обычных", "обычных"])(ArrayMethods.length)} и {staticMethods.length} {plural(["статический", "статических", "статических"])(ArrayMethods.length)}).</p>
      <p>Реализовать свои версии стандартных методов — хороший способ закрепить темы <Link to="/courses/basics">Условия и циклы</Link>, <Link to="/courses/fp">ФП</Link>, а также Итераторы, которые отдельно в рамках обучения не разбираются.</p>

      <MethodsTable methods={ArrayMethods} />
    </div>
  );
}

