import s from "./CourseDescription.module.css";

import React from "react";

export function BasicsCourseDescription() {
  return (
    <div className={s.fp}>
      <h2>Что мы изучаем</h2>
      <p>В этом разделе представлены задачи для закрепления базового синтаксиса джаваскрипта. По теории достаточно
        изучить раздел <a href="https://learn.javascript.ru/first-steps">Основы JavaScript</a> (learn.javascript.ru).
      </p>
      <h2>Основные темы</h2>
      <ul>
        <li>Арифметические и математические операции</li>
        <li>Операторы сравнения</li>
        <li>If и условный оператор</li>
        <li>Обращение к элементам массива и строк</li>
        <li>Циклы for и while</li>
        <li>Функции и их аргументы</li>
      </ul>
    </div>
  );
}

