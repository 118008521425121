import {useState} from "react";
import {MaxcodeProblem, problem2methods} from "../../../domain/problems/MaxcodeCourses";
import {JavascriptMethods} from "../../../domain/problems/js/JavascriptMethods";
import {Button} from "../../Button/Button";
import {MethodsTable} from "../../MethodsTable/MethodsTable";
import {useNavigate} from "react-router-dom";

interface MethodsHintProps {
  slug: string;
}

export function MethodsHint({slug}: MethodsHintProps) {
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const problemMethodsNames = problem2methods[slug as MaxcodeProblem];
  const methods = JavascriptMethods.filter(({method}) => problemMethodsNames?.includes(method));

  if (methods.length === 0) {
    return null;
  }

  if (!visible) {
    return (
      <div>
        <Button onClick={() => setVisible(true)}>Подсказка по методам</Button>
      </div>
    )
  }

  function openJavascriptCheatsheets(method: string) {
    const url = `/cheatsheets/javascript?method=${encodeURIComponent(method)}`;
    navigate(url);
  }

  return (
    <div>
      <h2>Методы, использующиеся в решении</h2>
      <MethodsTable
        methods={methods}
        onClick={({method}) => openJavascriptCheatsheets(method)}
        fullMethodName
      />
    </div>
  );
}
