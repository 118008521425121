import s from "./CourseDescription.module.css";
import React from "react";

export function BitsCourseDescription() {
  return (
    <div className={s.fp}>
      <h2>Какие темы разбираются в курсе</h2>

      <p className={s.first}>В этой серии разбирается всё, что связано с хранением чисел в памяти. Но сначала нам придется вспомнить математику начальной школы</p>

      <h3>Системы счисления</h3>
      <p>Мы научимся переводить в десятичную систему счисления и из нее. Отдельно разберемся с целыми и нецелыми числами.</p>

      <h3>Дроби</h3>
      <p>Компьютеры не умеют хранить дробные числа точно. Реализуем функции перевода обыкновенной дроби в десятичную и обратно. Напишем класс для работы со смешанными числами.</p>

      <h3>Цвета</h3>
      <p>Опираясь, на знания полученный в предыдущих задачах, конвертируем hex в rgb и обратно, а также научимся генерировать случайные цвета.</p>

      <h3>Представление чисел</h3>
      <p>Прежде, чем разбирать битовые операции, узнаем, как в памяти компьютера хранятся int и double (в JavaScript также известный как number).</p>

      <h3>Операции с битами</h3>
      <p>На практике используем операторы <code>&lt;&lt;</code>, <code>&gt;&gt;&gt;</code>, <code>^</code>, <code>&</code>, <code>|</code> и другие символы, которые получатся, если случайно поводить пальцем по клавиатуре с зажатым шифтом.</p>
    </div>
  );
}
