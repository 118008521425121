import {ArrayMethod, ArrayMethods} from "./ArrayMethods";
import {StringMethod, StringMethods} from "./StringMethods";
import {MathMethod, MathMethods} from "./MathMethods";
import {ObjectMethod, ObjectMethods} from "./ObjectMethods";
import {RegExpMethod, RegExpMethods} from "./RegExpMethods";
import {MapMethod, MapMethods} from "./MapMethods";
import {SetMethod, SetMethods} from "./SetMethods";
import {NumberMethod, NumberMethods} from "./NumberMethods";
import {PromiseMethod, PromiseMethods} from "./PromiseMethods";
import {FunctionMethod, FunctionMethods} from "./FunctionMethods";
import {GlobalMethod, GlobalMethods} from "./GlobalMethods";
import {OperatorMethod, OperatorMethods} from "./OperatorMethods";
import {IteratorMethod, IteratorMethods} from "./IteratorMethods";

export type JavascriptMethod =
  | ArrayMethod
  | StringMethod
  | RegExpMethod
  | MathMethod
  | ObjectMethod
  | MapMethod
  | SetMethod
  | NumberMethod
  | PromiseMethod
  | FunctionMethod
  | GlobalMethod
  | OperatorMethod
  | IteratorMethod

export type JavascriptMethodName = JavascriptMethod["method"];

export const JavascriptMethods = [
  ...ArrayMethods,
  ...StringMethods,
  ...MathMethods,
  ...ObjectMethods,
  ...RegExpMethods,
  ...MapMethods,
  ...SetMethods,
  ...NumberMethods,
  ...PromiseMethods,
  ...FunctionMethods,
  ...GlobalMethods,
  ...OperatorMethods,
  ...IteratorMethods,
];
