import React from "react";
import s from './TwoColumnLayout.module.css';
import {MainHeader} from "./MainHeader";

interface LayoutProps {
  children: React.ReactNode,
}

export function MainLayout({
  children,
}: LayoutProps) {
  return (
    <div className={s.layout}>
      <MainHeader />
      {children}
    </div>
  )
}


