import {
  algoCourse,
  arraysCourse,
  asyncCourse,
  basicsCourse,
  bitsCourse,
  fpCourse,
  objectsCourse,
  oopCourse,
  recursionCourse
} from "../../domain/problems/MaxcodeCourses";
import cn from "classnames";
import s from "./ProblemsProgress.module.css";
import {Link} from "react-router-dom";
import {useSelector} from "../../app/hooks";
import {Problem} from "../../api/problems";

const courses = [
  // basicsCourse,
  arraysCourse,
  objectsCourse,
  fpCourse,
  oopCourse,
  recursionCourse,
  asyncCourse,
  // bitsCourse,
  // algoCourse,
];

export function ProblemsProgress() {
  const problemsMap = useSelector(state => state.problems);

  return (
    <div className={s.list}>
      {courses.map(({id, title, topics}) => {
        const problems = topics.flatMap(s => s.problems);
        const solvedProblems = problems
          .filter(slug => {
            const problem = problemsMap[slug] as Problem | undefined;
            return problem !== undefined && problem.submissionsPreviews.some(sp => sp.status === "OK");
          });

        return (
          <Link
            key={id}
            className={cn(s.link, id)}
            to={"/courses/" + id}
          >
            <span className={cn(s.title, id)}>
              {title}
            </span>
            <span className={s.stat}>
              {solvedProblems.length} / {problems.length}
            </span>
          </Link>
        )
      })}
    </div>
  )
}
