export const GlobalMethods = [
  {
    method: "parseInt",
    argument: "string + radix",
    returnedValue: "number",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "B",
    example: `      
      parseInt("101", 2) === 5
    `,
  },
] as const;


export type GlobalMethod = typeof GlobalMethods[number];

