import { CourseId } from "../domain/problems/courses";

export async function sendPayment(courseId: CourseId, promocode: string | null): Promise<string> {
  const qs = new URLSearchParams();

  qs.set("courseId", courseId);
  if (promocode !== null) {
    qs.set("promocode", promocode);
  }

  const url = `/api/v1/payment/create-yookassa-link?` + qs;

  const resp = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: "POST",
  }).then(r => r.text());

  return resp;
}

export async function refreshPaymentStatus(yookassaPaymentId: string) {
  const url = `/api/v1/payment/refresh-status?yookassaPaymentId=${yookassaPaymentId}`;

  const resp = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: "POST",
  }).then(r => r.text());

  return resp;
}
