export const stackTopics = [
  {
    section: "Базовые задачи",
    problems: [
      {
        id: 155,
        title: "Min Stack",
        slug: "min-stack",
        difficulty: "medium",
      },
      {
        id: 150,
        title: "Evaluate Reverse Polish Notation",
        slug: "evaluate-reverse-polish-notation",
        difficulty: "medium",
      },
      {
        id: 84,
        title: "Largest Rectangle in Histogram",
        slug: "largest-rectangle-in-histogram",
        difficulty: "medium",
      },
      {
        id: 316,
        title: "Remove Duplicate Letters",
        slug: "remove-duplicate-letters",
        difficulty: "medium",
      },
      {
        id: 1047,
        title: "Remove All Adjacent Duplicates In String",
        slug: "remove-all-adjacent-duplicates-in-string",
        difficulty: "medium",
      },
      {
        id: 388,
        title: "Longest Absolute File Path",
        slug: "longest-absolute-file-path",
        difficulty: "medium",
      },
      {
        id: 636,
        title: "Exclusive Time of Functions",
        slug: "exclusive-time-of-functions",
        difficulty: "medium",
      },
      {
        id: 946,
        title: "Validate Stack Sequences",
        slug: "validate-stack-sequences",
        difficulty: "medium",
      },
      {
        id: 962,
        title: "Maximum Width Ramp",
        slug: "maximum-width-ramp",
        difficulty: "medium",
      },
      {
        id: 1003,
        title: "Check If Word Is Valid After Substitutions",
        slug: "check-if-word-is-valid-after-substitutions",
        difficulty: "medium",
      },
    ]
  },
  {
    section: "Скобочные последовательности",
    problems: [
      {
        id: 20,
        title: "Valid Parentheses",
        slug: "valid-parentheses",
        difficulty: "medium",
      },
      {
        id: 844,
        title: "Backspace String Compare",
        slug: "backspace-string-compare",
        difficulty: "medium",
      },
      {
        id: 1021,
        title: "Remove Outermost Parentheses",
        slug: "remove-outermost-parentheses",
        difficulty: "medium",
      },
      {
        id: 1614,
        title: "Maximum Nesting Depth of the Parentheses",
        slug: "maximum-nesting-depth-of-the-parentheses",
        difficulty: "medium",
      },
      {
        id: 678,
        title: "Valid Parenthesis String",
        slug: "valid-parenthesis-string",
        difficulty: "medium",
      },
      {
        id: 856,
        title: "Score of Parentheses",
        slug: "problems",
        difficulty: "medium",
      },
      {
        id: 921,
        title: "Minimum Add to Make Parentheses Valid",
        slug: "minimum-add-to-make-parentheses-valid",
        difficulty: "medium",
      },
    ],
  },
  {
    section: "Монотонный стек",
    problems: [
      {
        id: 402,
        title: "Remove K Digits",
        slug: "remove-k-digits",
        difficulty: "medium",
      },
      {
        id: 456,
        title: "132 Pattern",
        slug: "132-pattern",
        difficulty: "medium",
      },
      {
        id: 496,
        title: "Next Greater Element I",
        slug: "next-greater-element-i",
        difficulty: "medium",
      },
      {
        id: 503,
        title: "Next Greater Element II",
        slug: "next-greater-element-ii",
        difficulty: "medium",
      },
      {
        id: 735,
        title: "Asteroid Collision",
        slug: "asteroid-collision",
        difficulty: "medium",
      },
      {
        id: 1475,
        title: "Final Prices With a Special Discount in a Shop",
        slug: "final-prices-with-a-special-discount-in-a-shop",
        difficulty: "medium",
      },
      {
        id: 739,
        title: "Daily Temperatures",
        slug: "daily-temperatures",
        difficulty: "medium",
      },
      {
        id: 768,
        title: "Max Chunks To Make Sorted II",
        slug: "max-chunks-to-make-sorted-ii",
        difficulty: "medium",
      },
      {
        id: 901,
        title: "Online Stock Span",
        slug: "online-stock-span",
        difficulty: "medium",
      },
      {
        id: 907,
        title: "Sum of Subarray Minimums",
        slug: "sum-of-subarray-minimums",
        difficulty: "medium",
      },
    ],
  },
  {
    section: "Парсинг выражений",
    problems: [
      {
        id: 224,
        title: "Basic Calculator",
        slug: "basic-calculator",
        difficulty: "medium",
      },
      {
        id: 227,
        title: "Basic Calculator II",
        slug: "problems",
        difficulty: "medium",
      },
      {
        id: 385,
        title: "Mini Parser",
        slug: "mini-parser",
        difficulty: "medium",
      },
      {
        id: 394,
        title: "Decode String",
        slug: "decode-string",
        difficulty: "medium",
      },
      {
        id: 726,
        title: "Number of Atoms",
        slug: "number-of-atoms",
        difficulty: "medium",
      }
    ],
  },
];
