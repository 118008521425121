export const arrayMethodsTopics = [
  {
    section: "Перебирающие методы",
    problems: [
      "map",
      "filter",
      "every",
      "some",
      "for-each",
      "flat",
      "flat-map",
      "reduce",
      "reduce-right",
    ],
  },
  {
    section: "Поиск элементов",
    problems: [
      "includes",
      "index-of",
      "last-index-of",
      "find",
      "find-last",
      "find-index",
      "find-last-index",
    ],
  },
  {
    section: "Мутирующие методы",
    problems: [
      "reverse",
      "sort",
    ],
  },
  {
    section: "Немутирующие методы",
    problems: [
      "at",
      "slice",
      "join",
      "to-reversed",
      "to-sorted",
      "to-spliced",
      "with",
      "concat",
      "to-string",
      "to-locale-string",
    ],
  },
  {
    section: "Изменение значений",
    problems: [
      "pop",
      "push",
      "shift",
      "unshift",
      "splice",
      "fill",
      "copy-within",
    ],
  },
  {
    section: "Итераторы",
    problems: [
      "keys",
      "values",
      "entries",
    ],
  },
] as const;
