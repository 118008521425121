import React, {useState} from "react";
import s from './TwoColumnLayout.module.css';
import {MainLayout} from "./MainLayout";
import cn from "classnames";

interface TwoColumnLayout {
  left: React.ReactNode,
  right: React.ReactNode,
  leftLabel: string,
  rightLabel: string,
}

export function TwoColumnLayout({
  left,
  right,
  leftLabel,
  rightLabel,
}: TwoColumnLayout) {
  const [active, setActive] = useState("left");

  return (
    <MainLayout>
      <div className={cn(s.main, s.twoColumn)}>
        <div className={cn(s.left, active === "left" && s.active)}>
          <div className={s.inner}>
            {left}
          </div>
        </div>
        <div className={cn(s.right, active === "right" && s.active)}>
          <div className={s.inner}>
            {right}
          </div>
        </div>
      </div>
      <div className={s.switch}>
        {["left", "right"].map(x => (
          <label key={x} className={cn(s.label, x === active && s.selected)}>
            <input type="radio" name="switch" value={x} checked={x === active} onChange={() => setActive(x)}/>
            {x === "left" ? leftLabel : rightLabel}
          </label>
        ))}
      </div>
    </MainLayout>
  );
}
