import s from "./CourseDescription.module.css";

import React from "react";
import {MethodsTable} from "../../MethodsTable/MethodsTable";
import {ArrayMethods} from "../../../domain/problems/js/ArrayMethods";
import {StringMethods} from "../../../domain/problems/js/StringMethods";
import {plural} from "../../../domain/i18n/plural";
import {RegExpMethods} from "../../../domain/problems/js/RegExpMethods";

export function ArraysCourseDescription() {
  return (
    <div className={s.fp}>
      <h2>Какие темы разбираются в курсе</h2>

      <p>У массивов в джаваскрипте {ArrayMethods.length} {plural(["метод", "метода", "методов"])(ArrayMethods.length)},
        примерно половина из них используется в работе на постоянной основе.</p>
      <p>Цель этой серии — научиться по условию задачи определять, с помощью каких методов можно решить задачу и на
        какие шаги разбить решение, чтобы каждый из них закрыть каким-то методом.</p>
      <p>При этом иногда бывает так, что никакой метод нам не подходит, тогда нужно использовать классический for или
        for..of.</p>

      <h2>Методы массивов и строк</h2>

      <p>Для удобства здесь собраны все методы массивов. 😈 означает, что метод мутирующий и с ним нужно быть острожнее,
        😇 означает, что метод не мутирующий.</p>

      <p>При клике по заголовку методы сортируются. Ссылка ведет на MDN.</p>

      <MethodsTable methods={ArrayMethods}/>
      <MethodsTable methods={StringMethods}/>
      <MethodsTable methods={RegExpMethods}/>

    </div>
  );
}

