import React, {useState} from "react";
import s from "./CheatsheetEcmaScript.module.css";
import {JavascriptMethod, JavascriptMethodName, JavascriptMethods} from "../../domain/problems/js/JavascriptMethods";
import {MethodsTable} from "../../components/MethodsTable/MethodsTable";
import {FloatMethod} from "../../components/FloatMethod/FloatMethod";

type Syntax =
  | "**"
  | "Trailing commas in function parameter lists"
  | "Async Functions"

interface Version {
  year: number,
  methods: JavascriptMethodName[],
  syntax: string[],
}

const versions: Version[] = [
  {
    year: 2016,
    methods: ["Array#includes"],
    syntax: ["**"],
  },
  {
    year: 2017,
    methods: [
      "Object.values",
      "Object.entries",
      "String#padStart",
      "String#padEnd",
      "Object.getOwnPropertyDescriptors",
    ],
    syntax: [
      "Trailing commas in function parameter lists",
      "Async Functions",
      "RegExp Named Capture Groups",
      "Object Rest/Spread Properties",
    ],
  },
  {
    year: 2018,
    methods: [
      "Promise#finally",
    ],
    syntax: [
      "RegExp Named Capture Groups",
      "Object Rest/Spread Properties",
      "for-await-of",
      "Optional catch binding",
    ],
  },
  {
    year: 2019,
    methods: [
      "Object.fromEntries",
      "String#trimStart",
      "String#trimEnd",
      "Array#flat",
      "Array#flatMap",
    ],
    syntax: [
      "Optional catch binding"
    ],
  },
  {
    year: 2020,
    methods: [
      "Promise.allSettled",
    ],
    syntax: [
      "import()",
      "BigInt",
      "globalThis",
      "for-in mechanics",
      "Optional Chaining",
      "Nullish coalescing Operator",
      "import.meta",
    ],
  },
  {
    year: 2021,
    methods: [
      "String#replaceAll",
      "Promise.any",
    ],
    syntax: [
      "WeakRefs",
      "Logical Assignment Operators",
      "Numeric separators",
    ],
  },
  {
    year: 2022,
    methods: [
      "String#at",
      "Array#at",
      "Object.hasOwn",
    ],
    syntax: [
      "Class Fields",
      "Class Static Block",
      "Error Cause",
    ],
  },
  {
    year: 2023,
    methods: [
      "Array#findLast",
      "Array#findLastIndex",
      "Array#with",
      "Array#toReversed",
      "Array#toSorted",
      "Array#toSpliced",
    ],
    syntax: [],
  },
  {
    year: 2024,
    methods: [
      "Map.groupBy",
      "Object.groupBy",
      "Promise.withResolvers",
    ],
    syntax: [],
  },
];

export function CheatsheetEcmaScript() {
  const [selectedMethod, setSelectedMethod] = useState<JavascriptMethod | null>(null);

  return (
    <div className={s.wrapper}>
      <h1 className={s.mainTitle}>Фичи EcmaScript по годам</h1>
      <div className={s.memo}>
        <p>Начиная с 2016 года, JavaScript перешел на ежегодные обновления. Каждый год в языке появляются новые методы и новый синтаксис. Как правило, начинать пользоваться ими можно раньше, чем они появляются в стандарте.</p>
        <p>В тестирующей системе <strong>maxcode.dev</strong> всегда самая актуальная версия Node.js.</p>
      </div>
      <FloatMethod method={selectedMethod} onClose={() => setSelectedMethod(null)}/>
      {[...versions].reverse().map(({year, syntax, methods}) => {
        const methodsObj = JavascriptMethods.filter(({method}) => methods.includes(method));

        return (
          <div className={s.version} data-year={year}>
            {syntax.length > 0 && (
              <div className={s.syntax}>
                <div className={s.syntaxHeader}>Синтаксис</div>
                <ul>{syntax.map(s => <li>{s}</li>)}</ul>
              </div>
            )}
            {methodsObj.length > 0 && (
              <div>
                <MethodsTable
                  methods={methodsObj}
                  onClick={setSelectedMethod}
                  fullMethodName
                />
              </div>
            )}
          </div>
        )
      })}
    </div>
  );
}

