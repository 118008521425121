export function formatPrice(price: number) {
  const str = price.toFixed(0);
  return str
    .split("")
    .map((num, i) => {
      const iFromEnd = str.length - i;
      if (iFromEnd % 3 === 0) {
        return ' ' + num
      }
      return num
    })
    .join("");
}
