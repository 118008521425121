import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import {useDispatch} from "../../app/hooks";
import {sendGithubCodeThunk} from "../../redux/userSlice";

export function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [search] = useSearchParams();
  const code = search.get("code")!;
  const path = search.get("path")!;
  const decodedPath = window.decodeURIComponent(path);

  useEffect(() => {
    dispatch(sendGithubCodeThunk(code)).then(() => {
      navigate(decodedPath);
    });
  }, []);

  return (
    <div />
  )
}
