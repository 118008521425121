import s from "./CourseDescription.module.css";

export function FpCourseDescription() {
  return (
    <div className={s.fp}>
      <h2>Какие темы разбираются в курсе</h2>

      <h3>Функции высшего порядка</h3>
      <p>Джаваскрипт — мультипарадигменый язык. Поддержка концепций из функционального программирования была в нем с момента создания. Основная идея ФП — использование функций как значений.</p>
      <p>Мы начнем с задач, где функции принимают функции. Далее разберем задачи, где функции возвращают функции. И наконец напишем функции, которые принимают функции и возвращают функции!</p>
      <p>Отдельно разберем частичное применение, карирование и классику собеседований — функцию <code>sum(1)(2)(3)</code>.</p>


      <h3>Тестирование</h3>
      <p>С unit-тестами вы сталкивались каждый раз, когда отправляли задачу на проверку. Настало время разобраться, как писать тесты самостоятельно.</p>
      <p>Для создания тестов используются две библиотеки: одна выполняет роль test runner, а другая — assertion library. Мы рассмотрим, как устроены тесты, и реализуем базовые assertion-функции для проверок.</p>

      <h3>Реализуем Redux</h3>
      <p>Многие популярные библиотеки написаны в функциональном стиле. Например, Redux почти 10 лет остается первым выбором, когда необходимо использовать state manager в React.</p>
      <p>Мы подробно разберем, как устроена функция <code>createStore</code>, разберемся в магии <code>combineReducers</code> и применим все знания из первой части курса, чтобы реализовать <code>applyMiddlewares</code>.</p>
      <p>В реальных проектах redux используется вместе с библиотекой redux-tooklit. Разберем основные методы, поставляемый тулкитом, как то: <code>createSlice</code>, <code>createAsyncThunk</code>, <code>createListenerMiddleware</code> и <code>createEntityAdapter</code>.</p>
      <p>Даже если вы не используете React в работе, Redux является отличным примером реализации паттерна Event Emitter, который стоит разобрать.</p>

      <h3>Прикладной TypeScript</h3>
      <p>Задачи из этой темы отлично подходят для изучения TypeScript, поэтому большую часть решений мы дополнительно напишем на тайпскрипте, используя дженерики (generic types).</p>
      <p>Мы будем активно пользоваться как стандартными Utility Types, так и писать свои функции, параметризованные несколькими типами.</p>
    </div>
  );
}
