import s from "./RoadmapDsa.module.css";
import React from "react";
import {Helmet} from "react-helmet-async";
import {CoursesList} from "../../components/CoursesList/CoursesList";
import {LeetcodeCourses} from "../../domain/problems/leetcode/LeetcodeCourses";
import {bitsTopics} from "../../domain/problems/maxcode/topics/bitsTopics";
import {algoTopics} from "../../domain/problems/maxcode/topics/algoTopics";
import {algoCourse, bitsCourse} from "../../domain/problems/MaxcodeCourses";

export function RoadmapDsa() {

  return (
    <div className={s.wrapper}>
      <Helmet>
        <title>
          Практический роадмап по алгоритмам
        </title>
      </Helmet>
      <h1 className={s.mainTitle}>Алгоритмы и структуры данных</h1>
      <div className={s.memo}>
        <p><b>Сколько задач нужно решить?</b> Есть примерно 20 основных тем, список на этой странице. В каждой теме для закрепления нужно решить 15-20 задач, что дает 300-400 задач всего. На самом литкоде более 3000 задач, но их все решать точно не надо.</p>
        <p><b>Сколько времени это занимает?</b> Если решать по 8-12 задач в неделю, то от 6 до 12 месяцев. Это примерно тот же объем, который разбирают студенты-программисты на первом курсе в течение двух семестров.</p>
        <p><b>В каком порядке решать задачи?</b> Я рекомендую разбирать задачи по отдельным темам. Сами темы более-менее независимы, поэтому разбирать можно практически в любом порядке.</p>

      </div>

      <h2 className={s.sectionHeader}>Подборки задач с Leetcode по темам</h2>
      <CoursesList courses={[
        ...LeetcodeCourses.filter(x => x.topics.length !== 0),
        ...LeetcodeCourses.filter(x => x.topics.length === 0),
      ]}/>

      {/*<h2 className={s.sectionHeader}>Вводные курсы от maxcode.dev</h2>*/}
      {/*<CoursesList courses={[algoCourse, bitsCourse]}/>*/}
    </div>
  );
}
