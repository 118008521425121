import s from "./CourseDescription.module.css";
import {Link} from "react-router-dom";
import {useSelector} from "../../../app/hooks";
import cn from "classnames";
import {problemsPlural} from "../../../domain/i18n/plural";
import React from "react";
import {asyncTopics} from "../../../domain/problems/maxcode/topics/asyncTopics";
import {AsyncProblem, MaxcodeProblem} from "../../../domain/problems/MaxcodeCourses";

const problems: [AsyncProblem, string][] = [
  ["add-two-promises", "19:30"],
  ["add-all-promises", "34:46"],
  ["promise-logic", "21:50"],
  ["count-fulfilled-promises", "26:23"],
  ["race", "12:56"],
  ["simple-all", "14:13"],
  ["all", "28:41"],
  ["all-settled", "9:53"],
  ["any", "7:42"],
  ["time-limit", "7:07"],
  // ["cancelable-promise", "00:00"],
  ["sleep", "7:09"],
  ["microtasks", "10:06"],
  ["event-loop", "8:53"],
  ["state", "12:51"],
  ["chain", "10:21"],
  ["then", "10:00"],
  ["catch", "10:43"],
  ["finally", "21:58"],
  ["compose-async", "28:12"],
  ["polling", "19:09"],
  ["promise-batch", "19:23"],
  ["retry", "34:19"],
  ["promise-pool", "13:12"],
  ["promise-pool-memo", "32:48"],
  ["async-try-catch", "08:20"],
  ["async-event-loop", "10:02"],
  ["boost-async-flatten", "11:11"],
  ["promisify", "14:50"],
  ["callbackify", "18:30"],
  ["compose-callback", "21:03"],
  ["file-system", "01:06:06"],
  ["products", "49:04"],
  // ["promise-diy", "00:00"],
  // ["async-await-diy", "00:00"],
  ["cache", "15:35"],
  ["debounce", "21:44"],
  ["throttle", "23:39"],
];

export function AsyncProblemsDescription() {
  const problems = asyncTopics.flatMap(t => t.problems);

  return (
    <div>
      <h2>Какие темы разбираются в курсе</h2>
      <p>Курс состоит из {problems.length} {problemsPlural(problems.length)} и разделен на {asyncTopics.length} разделов, которые удобно проходить последовательно.</p>
      <ol>
        <li>
          Начинаем c разминочных задач, которые можно решить с помощью стандартных методов промисов. Кроме этого, предлагается несколько подходов к решению, как можно решить задачи с нуля.
        </li>
        <li>
          Далее реализуем наиболее интересные статические методы. Это одновременно и практика использования, и
          практика создания промисов.
        </li>
        <li>
          Проверяем на практике, как работает event loop. Решаем задачи на понимание того, как работают очереди микро- и
          макротасок.
        </li>
        <li>
          Далее мы подробно разбираем, как работают методы <code>then</code>, <code>catch</code> и <code>finally</code>.
          Узнаем страшную тайну последних двух!
        </li>
        <li>
          Отдельный раздел посвящен тому, как запускать функции последовательно, параллельно, батчами и в формате
          асинхронной очереди. Это то, что вас спросят на собеседовании! И то, что пригодится на работе.
        </li>
        <li>
          <code>async/await</code> является синтаксическим сахаром над промисами, поэтому мы разбираем типовые ошибки и
          перерешиваем задачи из предыдущих разделов, используя новый синтаксис.
        </li>
        <li>
          В разделе про колбэки разбираем исторически предшествующий промисам способ работы с асинхронностью.
          Разбираемся, как писали раньше и как подружить колбэки с промисами.
        </li>
        <li>
          Задачи на рекурсивные структуры данных. Тут придется использовать все, что мы разобрали в предыдущих темах,
          обходя файловую систему и фильтруя каталог товаров.
        </li>
        {/*<li>*/}
        {/*  Какой курс по промисам без своей реализации промисов? Напишем свой класс <code>MyPromise</code>, который будет*/}
        {/*  поддерживать все основные возможности промиса.*/}
        {/*</li>*/}
        <li>
          В заключительном разделе решаем задачи на <code>setTimeout</code>, в том числе классические
          задачи <code>throttle</code> и <code>debounce</code>.
        </li>
      </ol>
    </div>
  );
}

export function AsyncCourseDescription() {
  const problems = asyncTopics.flatMap(t => t.problems);

  return (
    <div className={s.async}>
      <AsyncProblemsDescription />
      <Playlist whitelist={problems} />
    </div>
  );
}

interface PlaylistProps {
  whitelist: AsyncProblem[];
  title?: string;
}

export function Playlist({ whitelist, title }: PlaylistProps) {
  const problemsMap = useSelector(state => state.problems);
  const filteredProblems = problems
    .filter(([slug]) => problemsMap[slug] !== undefined)
    .filter(([slug]) => whitelist.includes(slug));

  return (
    <div>
      <h2>{title ?? "Плейлист видеоразборов"}</h2>
      <div className={s.youtubeList}>
        {filteredProblems
          .map(([slug, duration]) => {
            return (
              <div className={s.youtubeRow}>
                <div className={s.youtubeTitle}>
                  <Link
                    to={"/problems/" + slug}
                    className={cn(s.youtubeLink, problemsMap[slug].difficulty)}
                  >
                    {problemsMap[slug].title}
                  </Link>
                </div>
                <span className={s.youtubeDuration}>{duration}</span>
              </div>
            )
          })}
        <div className={s.youtubeRow}>
          <div className={s.youtubeTitle}>
          </div>
          <span className={s.youtubeDuration}>
            <strong>{durationSum(filteredProblems.map(([_, duration]) => duration))}</strong>
          </span>
        </div>
      </div>
    </div>
  )
}

function durationSum(durations: string[]) {
  const seconds = durations.reduce((acc, duration) => {
    const [s = 0, m = 0, h = 0] = duration.split(":").map(Number).reverse();
    return acc + s + m * 60 + h * 60 * 60;
  }, 0);

  const s = seconds % 60;
  const m = Math.floor(seconds / 60) % 60;
  const h = Math.floor(seconds / 60 / 60);

  return `${h.toString().padStart(2, "0")}:${m.toString().padStart(2, "0")}:${s.toString().padStart(2, "0")}`;
}
