import {Problem} from "../../../api/problems";
import {useDispatch, useSelector} from "../../../app/hooks";
import {SubmissionsHistory} from "../SubmissionsHistory/SubmissionsHistory";
import {useState} from "react";
import {OneSubmission} from "./OneSubmission";
import s from "./ProblemSubmissions.module.css";
import {submissionsSlice} from "../../../redux/submissionsSlice";

interface ProblemDescriptionProps {
  problem: Problem;
}

export function ProblemSubmissions({problem}: ProblemDescriptionProps) {
  const { slug } = problem;
  const dispatch = useDispatch();
  const submissionsPreviews = useSelector(state => state.problems[slug].submissionsPreviews);
  const lastToken = submissionsPreviews.at(-1)?.token ?? null;
  const submissionToken = useSelector(state => state.submissions.slug2openSubmission[slug] ?? lastToken);

  function setSubmissionToken(token: string | null) {
    dispatch(submissionsSlice.actions.setOpenSubmission({ slug, token }));
  }

  if (submissionsPreviews.length === 0 || submissionToken === null) {
    return (
      <div className={s.wrapper}>
        <h1>Результаты проверки</h1>
        <p>Не было попыток отправить решение</p>
      </div>
    );
  }

  if (submissionsPreviews.at(-1)?.token === submissionToken) {
    return (
      <div>
        <OneSubmission
          submissionToken={submissionToken}
          problemSlug={slug}
        />
        <SubmissionsHistory
          problemSlug={slug}
          onSelect={setSubmissionToken}
        />
      </div>
    );
  }

  return (
    <div>
      <button
        className={s.lastSubmissionButton}
        onClick={() => setSubmissionToken(lastToken)}
      >
        ← Последняя отправка
      </button>
      <OneSubmission
        submissionToken={submissionToken}
        problemSlug={slug}
        isPrevSubmission
      />
    </div>
  )
}

