import s from "./FloatMethod.module.css";
import {JavascriptMethod} from "../../domain/problems/js/JavascriptMethods";
import {methods2problems} from "../../domain/problems/MaxcodeCourses";
import {ProblemLink} from "../CoursePage/CourseProblemsList/CourseProblemsList";
import {MarkdownRenderer} from "../ProblemPage/ProblemDescription/MarkdownRenderer";

interface FloatMethodProps {
  method: JavascriptMethod | null;
  onClose: () => void;
}

export function FloatMethod({method, onClose}: FloatMethodProps) {
  if (method === null) {
    return null;
  }
  return (
    <div className={s.float}>
      <button onClick={onClose} className={s.close}>×</button>
      <h2 className={s.header}>{method.method}</h2>
      {"example" in method && (
        <MarkdownRenderer>
          {`~~~js\n${method
            .example
            .trim()
            .split("\n")
            .map(x => x.trim())
            .join("\n")
          }\n~~~`}
        </MarkdownRenderer>
      )}
      {methods2problems[method.method] && (
        <div className={s.problemsList}>
          {methods2problems[method.method]
            ?.map(slug => <ProblemLink slug={slug} white/>)}
        </div>
      )}
    </div>
  )
}
