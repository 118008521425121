export const NumberMethods = [
  {
    method: "Number.isFinite",
    argument: "number",
    returnedValue: "boolean",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "Number.isInteger",
    argument: "number",
    returnedValue: "boolean",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "Number.isNaN",
    argument: "number",
    returnedValue: "boolean",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "Number.isSafeInteger",
    argument: "number",
    returnedValue: "boolean",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "Number.parseFloat",
    argument: "string",
    returnedValue: "number",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "Number.parseInt",
    argument: "string",
    returnedValue: "number",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "Number#toExponential",
    argument: "number",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "Number#toFixed",
    argument: "number",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "Number#toLocaleString",
    argument: "number",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "Number#toPrecision",
    argument: "number",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "Number#toString",
    argument: "number",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "Number#valueOf",
    argument: "number",
    returnedValue: "number",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "D",
  },
] as const;

export type NumberMethod = typeof NumberMethods[number];

