import s from "../CourseDescription/CourseDescription.module.css";
import {Link} from "react-router-dom";
import {useSelector} from "../../../app/hooks";
import {CourseBuyProps} from "./CourseBuyProps";
import {AsyncLandingPromoLink} from "../CoursesPageContent/CoursesPageContent";

export function AsyncCourseBuy({openDescription}: CourseBuyProps) {
  const courses = useSelector(s => s.user.courses);
  const asyncCourse = courses.find(course => ["async-pro", "async"].includes(course.courseId));

  function handleClick(e: React.MouseEvent) {
    e.preventDefault();
    openDescription();
  }

  return (
    <div className={s.async}>
      <h2>О подборке задач</h2>
      <p className={s.first}>Серия задач от maxcode.dev — самый полный практический курс по асинхронности на русском
        языке</p>

      <p>В интернете полно информации про промисы, но научиться программированию можно только только на практике, решая
        задачи.</p>

      <p>Вся теория описана на MDN, а на странице со <Link to="/cheatsheets/promises">шпаргалкой по
        промисам</Link> выделена основная информация. В курсе собраны задачи, для решения которых нужно написать код и пройти все тесты. Для каждой задачи доступно авторское решение с видеоразбором.</p>

      <p>Большая часть задач доступна для всех. Остальные задачи и разборы решений доступны записавшимся на <a target="_blank"
                                                                                             href="https://sinyakov.com/frontend/">индивидуальные
        занятия</a> или при покупке курса.</p>

      {asyncCourse === undefined ? (
        <div style={{fontSize: 15}}>
          <AsyncLandingPromoLink/>
        </div>
      ) : (
        <div className={s.access} style={{background: "#90edbd"}}>
          <p>Задачи доступны до {new Date(asyncCourse.endedAt).toLocaleDateString("ru-RU")}</p>
        </div>
      )}
    </div>
  );
}
