import {useTranslation} from "../../domain/i18n/i18n";
import {plural, problemsPlural} from "../../domain/i18n/plural";
import {Link} from "react-router-dom";
import cn from "classnames";
import s from "./CoursesList.module.css";
import React from "react";
import {RuCourse} from "../../domain/problems/courses";

interface CoursePromoLinkProps {
  course: RuCourse;
}

export function CoursePromoLink({course}: CoursePromoLinkProps) {
  const {topics, title, id} = course;
  // @ts-ignore
  const problems = topics.flatMap(x => x.problems);

  const len = problems.length !== 0
    ? problems.length
    : 15 + id.length % 8;

  if (problems.length === 0) {
    return (
      <span
        key={id}
        className={cn(s.link, id, s.locked)}
      >
        <h2 className={s.title}>{title}</h2>
        <span className={s.problems}>
        {len} {problemsPlural(len)}
      </span>
      </span>
    );
  }

  return (
    <Link
      key={id}
      className={cn(s.link, id)}
      to={"/courses/" + id}
    >
      <h2 className={s.title}>{title}</h2>
      <span className={s.problems}>
        {len} {problemsPlural(len)}
      </span>
    </Link>
  );
}
