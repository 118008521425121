export const MapMethods = [
  {
    method: "Map.groupBy",
    argument: "iterable",
    returnedValue: "Map",
    mutable: false,
    nodeVersion: "21.0.0",
    year: 2023,
    tier: "A",
  },
  {
    method: "Map#get",
    argument: "key",
    returnedValue: "value",
    mutable: false,
    nodeVersion: "0.12.0",
    year: 2015,
    tier: "A",
  },
  {
    method: "Map#has",
    argument: "key",
    returnedValue: "boolean",
    mutable: false,
    nodeVersion: "0.12.0",
    year: 2015,
    tier: "A",
  },
  {
    method: "Map#set",
    argument: "key + value",
    returnedValue: "this",
    mutable: true,
    nodeVersion: "0.12.0",
    year: 2015,
    tier: "A",
  },
  {
    method: "Map#delete",
    argument: "key",
    returnedValue: "boolean",
    mutable: true,
    nodeVersion: "0.12.0",
    year: 2015,
    tier: "A",
  },
  {
    method: "Map#clear",
    argument: "—",
    returnedValue: "—",
    mutable: true,
    nodeVersion: "0.12.0",
    year: 2015,
    tier: "A",
  },
  {
    method: "Map#forEach",
    argument: "callback",
    returnedValue: "—",
    mutable: false,
    nodeVersion: "0.12.0",
    year: 2015,
    tier: "D",
  },
  {
    method: "Map#entries",
    argument: "—",
    returnedValue: "iterator",
    mutable: false,
    nodeVersion: "0.12.0",
    year: 2015,
    tier: "D",
  },
  {
    method: "Map#keys",
    argument: "—",
    returnedValue: "iterator",
    mutable: false,
    nodeVersion: "0.12.0",
    year: 2015,
    tier: "D",
  },
  {
    method: "Map#values",
    argument: "—",
    returnedValue: "iterator",
    mutable: false,
    nodeVersion: "0.12.0",
    year: 2015,
    tier: "D",
  },
] as const;

export type MapMethod = typeof MapMethods[number];
