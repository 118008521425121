import {createSlice} from "@reduxjs/toolkit";
import {JsRoadmapPromoMainCourses} from "../components/JsRoadmapPromo/JsRoadmapPromo";

interface State {
  index: number;
}

export const promoRoadmapJsSlice = createSlice({
  name: "promoRoadmapJs",
  initialState: {index: 0} as State,
  reducers: {
    prev(state) {
      state.index = Math.max(state.index - 1, 0);
    },
    next(state) {
      state.index = Math.min(state.index + 1, JsRoadmapPromoMainCourses.length - 1);
    }
  },
});
