import {SubmissionPreview, SubmissionPreviewWithCode} from "./problems";

export async function sendSubmission(code: string, slug: string, lang: string): Promise<string> {
  const url = `/api/v1/submissions/${slug}?lang=${lang}`;

  const resp = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: "POST",
    body: JSON.stringify({code})
  }).then(r => r.json());

  return resp.token;
}

const testExample = {
  "indentation": 0,
  "diagnostic": {
    "duration_ms": 2.550579,
    "location": "/box/script.js:43:1",
    "failureType": "testCodeFailure",
    "error": "promises is not defined",
    "code": "ERR_TEST_FAILURE",
    "name": "ReferenceError",
    "stack": "/box/script.js:9:9\nnew Promise (<anonymous>)\nall (/box/script.js:4:12)\nTestContext.<anonymous> (/box/script.js:48:32)\nTest.runInAsyncScope (node:async_hooks:206:9)\nTest.run (node:internal/test_runner/test:631:25)\nTest.start (node:internal/test_runner/test:542:17)\nstartSubtest (node:internal/test_runner/harness:208:17)"
  },
  "comment": null,
  "status": "NOT_OK",
  "testNumber": 1,
  "description": "- all accepts an array of promises",
  "directive": null,
  "subtest": null,
  "comments": [
    {
      "indentation": 0,
      "diagnostic": {},
      "comment": null,
      "text": "Subtest: the result array keeps order",
      "inline": false
    }
  ]
};

export type TestResult = typeof testExample;
export type SubmissionStatus =
  | "PENDING"
  | "TIME_LIMIT"
  | "RUNTIME_ERROR"
  | "OK"
  | "CANCELED"
  | "FAILED";

export interface SubmissionResult {
  status: SubmissionStatus;
  stderr: string | null;
  stdout: string | null;
  testResults: TestResult[];
}

export async function getSubmissionResult(token: string): Promise<SubmissionResult> {
  const url = `/api/v1/submissions?token=${token}`;

  const resp = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: "PATCH",
  }).then(r => r.json());

  return resp;
}

export interface SubmissionDetails {
  submissionPreview: SubmissionPreview;
  submissionResult: SubmissionResult;
  code: string;
}

export async function getSubmissionDetails(token: string): Promise<SubmissionDetails> {
  const url = `/api/v1/submissions?token=${token}`;

  const resp = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: "GET",
  }).then(r => r.json());

  return resp;
}

export async function cancelSubmission(token: string): Promise<void> {
  const url = `/api/v1/submissions/cancel/${token}`;

  await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: "POST",
  });
}


export async function getUserSubmissions(pageNumber: number): Promise<SubmissionPreviewWithCode[]> {
  const url = `/api/v1/submissions/list?pageNumber=${pageNumber}`;

  const resp = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: "GET",
  }).then(r => r.json());

  return resp;
}
