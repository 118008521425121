import React from "react";
import cn from "classnames";
import {useSelector} from "../../../app/hooks";
import s from "./CourseProblemsContent.module.css";
import {CourseId, courseId2topics} from "../../../domain/problems/courses";
import {CourseProblemsList} from "./CourseProblemsList";
import {isLeetcodeCourse} from "../../../domain/problems/leetcode/LeetcodeCourses";
import {LeetcodeCourseProblemsList} from "./LeetcodeCourseProblemsList";

interface ProblemsListProps {
  courseId: CourseId;
}

export function CourseProblemsContent({courseId}: ProblemsListProps) {
  const lang = useSelector(state => state.settings.lang);
  const {title} = courseId2topics[courseId][lang];

  return (
    <div className={s.wrapper}>
      <h1 className={cn(s.listTitle, courseId)}>
        {title}
      </h1>
      {isLeetcodeCourse(courseId) ? (
        <LeetcodeCourseProblemsList courseId={courseId} />
      ) : (
        <CourseProblemsList courseId={courseId} />
      )}
    </div>
  );
}
