import {fetchProblemsThunk} from "./redux/problemsSlice";
import {useDispatch, useSelector} from "./app/hooks";
import React, {useEffect} from "react";
import {createBrowserRouter, RouterProvider, useNavigation} from "react-router-dom";
import {ProblemPage} from "./pages/ProblemPage/ProblemPage";
import {CourseProblemsPage} from "./pages/CourseProblemsPage/CourseProblemsPage";
import {Login} from "./pages/Login/Login";
import {CoursesPage} from "./pages/CoursesPage/CoursesPage";
import {AuthPage} from "./pages/AuthPage/AuthPage";

import "./domain/problems/courses.css";
import {AsyncCourseLanding} from "./pages/AsyncCourseLanding/AsyncCourseLanding";
import {HomePage} from "./pages/HomePage/HomePage";
import {OneColumnLayout} from "./components/Layout/OneColumnLayout";
import {RoadmapJavaScript} from "./pages/RoadmapJavaScript/RoadmapJavaScript";
import {RoadmapTypeScript} from "./pages/RoadmapTypescript/RoadmapTypeScript";
import {ProfilePage} from "./pages/ProfilePage/ProfilePage";
import {RoadmapDsa} from "./pages/RoadmapDsa/RoadmapDsa";
import {CheatsheetJavaScript} from "./pages/CheatsheetJavaScript/CheatsheetJavaScript";
import {PaymentPage} from "./pages/PaymentPage/PaymentPage";
import {CheatsheetPromises} from "./pages/CheatsheetPromises/CheatsheetPromises";
import {fetchPaymentStatusThunk} from "./redux/paymentsSlice";
import {JsRoadmapPromo} from "./components/JsRoadmapPromo/JsRoadmapPromo";
import {ProblemsCompilationPage} from "./pages/ProblemsCompilationPage/ProblemsCompilationPage";
import {AsciiTable} from "./components/AsciiTable/AsciiTable";
import {CheatsheetEcmaScript} from "./pages/CheatsheetEcmaScript/CheatsheetEcmaScript";
import {TelegramNews} from "./components/TelegramNews/TelegramNews";
import {RoadmapReact} from "./pages/RoadmapReact/RoadmapReact";
import {HelpPage} from "./pages/HelpPage/HelpPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/profile",
    element: <ProfilePage />,
  },
  {
    path: "/cheatsheets/javascript",
    element: <OneColumnLayout content={<CheatsheetJavaScript />} />,
  },
  {
    path: "/cheatsheets/ecmascript",
    element: <OneColumnLayout content={<CheatsheetEcmaScript />} />,
  },
  {
    path: "/news",
    element: <OneColumnLayout content={<TelegramNews />} />,
  },
  {
    path: "/cheatsheets/promises",
    element: <OneColumnLayout content={<CheatsheetPromises />} />,
  },
  {
    path: "/cheatsheets/ascii",
    element: <OneColumnLayout content={<AsciiTable />} />,
  },
  {
    path: "/courses",
    element: <CoursesPage />,
  },
  {
    path: "/promo/async",
    element: <OneColumnLayout content={<AsyncCourseLanding />} />,
  },
  {
    path: "/promo/js-roadmap",
    element: <OneColumnLayout content={<JsRoadmapPromo />} />,
  },
  {
    path: "/roadmaps/javascript",
    element: <OneColumnLayout content={<RoadmapJavaScript />} />,
  },
  {
    path: "/roadmaps/typescript",
    element: <OneColumnLayout content={<RoadmapTypeScript />} />,
  },
  {
    path: "/roadmaps/react",
    element: <OneColumnLayout content={<RoadmapReact />} />,
  },
  {
    path: "/roadmaps/dsa",
    element: <OneColumnLayout content={<RoadmapDsa />} />,
  },
  {
    path: "/courses/:courseId",
    element: <CourseProblemsPage />,
  },
  {
    path: "/problems/",
    element: <CoursesPage />,
  },
  {
    path: "/problems-compilation/",
    element: <ProblemsCompilationPage />,
  },
  {
    path: "/payment/",
    element: <PaymentPage />,
  },
  {
    path: "/help/",
    element: <HelpPage />,
  },
  {
    path: "/payment-done/",
    element: <CoursesPage />,
  },
  {
    path: "/problems/:slug",
    element: <ProblemPage />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/auth",
    element: <AuthPage />,
  },
]);

export function App() {
  const dispatch = useDispatch();
  const loaded = useSelector(state => state.user.loaded);

  useEffect(() => {
    dispatch(fetchProblemsThunk()).then(() => {
      dispatch(fetchPaymentStatusThunk());
    })
  }, []);

  if (!loaded) {
    return null;
  }


  return <RouterProvider router={router} />;
}
