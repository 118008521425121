import {GithubAuthLink} from "../GithubAuthLink/GithubAuthLink";
import s from "./GithubAuthWindow.module.css";

export function GithubAuthWindow() {
  return (
    <div className={s.window}>
      <h2>Авторизуйтесь для&nbsp;доступа&nbsp;к&nbsp;задачам</h2>
      <div className={s.gradient}>
        <div className={s.wrapper}>
          <GithubAuthLink className={s.button} />
        </div>
      </div>
    </div>
  );
}
