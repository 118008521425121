export const RegExpMethods = [
  {
    method: "RegExp#test",
    argument: "string",
    returnedValue: "boolean",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
    example: `      
      /^a/.test("abc") === true
      /^a/.test("qwe") === false
      
      /\d+/.test("123") === true
      /\d+/.test("1q2w3e") === true
      /\d+/.test("qwe") === false
    `,
  },
] as const;

export type RegExpMethod = typeof RegExpMethods[number];

