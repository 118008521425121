export const binSearch = [
  {
    section: "Обычный бинарный поиск",
    problems: [
      {
        id: 33,
        title: "Search in Rotated Sorted Array",
        slug: "search-in-rotated-sorted-array",
        difficulty: "medium"
      },
      {
        id: 74,
        title: "Search a 2D Matrix",
        slug: "search-a-2d-matrix",
        difficulty: "medium"
      },
      {
        id: 153,
        title: "Find Minimum in Rotated Sorted Array",
        slug: "find-minimum-in-rotated-sorted-array",
        difficulty: "medium"
      },
      {
        id: 240,
        title: "Search a 2D Matrix II",
        slug: "search-a-2d-matrix-ii",
        difficulty: "medium"
      },
      {
        id: 367,
        title: "Valid Perfect Square",
        slug: "valid-perfect-square",
        difficulty: "medium"
      },
      {
        id: 875,
        title: "Koko Eating Bananas",
        slug: "koko-eating-bananas",
        difficulty: "medium"
      },
      {
        id: 981,
        title: "Time Based Key-Value Store",
        slug: "time-based-key-value-store",
        difficulty: "medium"
      },
    ],
  },
  {
    section: "lower_bound / upper_bound",
    problems: [
      {
        id: 34,
        title: "Find First and Last Position of Element in Sorted Array",
        slug: "find-first-and-last-position-of-element-in-sorted-array",
        difficulty: "medium"
      },
      {
        id: 528,
        title: "Random Pick with Weight",
        slug: "random-pick-with-weight",
        difficulty: "medium"
      },
      {
        id: 2080,
        title: "Range Frequency Queries",
        slug: "range-frequency-queries",
        difficulty: "medium"
      },
      {
        id: 911,
        title: "Online Election",
        slug: "online-election",
        difficulty: "medium"
      },
      {
        id: 1146,
        title: "Snapshot Array",
        slug: "snapshot-array",
        difficulty: "medium"
      },
    ],
  },
];
