import {TwoColumnLayout} from "../../components/Layout/TwoColumnLayout";
import {ProblemContent} from "../../components/ProblemPage/ProblemContent/ProblemContent";
import {Editor} from "../../components/ProblemPage/Editor/Editor";
import {ProblemDescription} from "../../components/ProblemPage/ProblemDescription/ProblemDescription";
import {ProblemSubmissions} from "../../components/ProblemPage/ProblemSubmissions/ProblemSubmissions";
import {ProblemSolution} from "../../components/ProblemPage/ProblemSolution/ProblemSolution";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "../../app/hooks";
import {CourseMainContent} from "../../components/CoursePage/CourseMainContent/CourseMainContent";
import {courseIdByProblemSlug} from "../../domain/problems/maxcode/courseIdByProblemSlug";
import {HiddenProblem} from "../../components/HiddenProblem/HiddenProblem";
import {Helmet} from "react-helmet-async";
import {fetchSubmissionsDetailsThunk} from "../../redux/submissionsSlice";

export const ContentComponent = {
  description: ProblemDescription,
  submissions: ProblemSubmissions,
  solution: ProblemSolution,
} as const;
export type View = keyof typeof ContentComponent;
export const views = Object.keys(ContentComponent) as View[];

export function ProblemPage() {
  const slug = useParams().slug!;
  const problem = useSelector(state => state.problems[slug]);
  const [view, setView] = useState<View>("description");
  const dispatch = useDispatch();

  useEffect(() => {
    const token = problem?.submissionsPreviews.at(-1)?.token;
    if (token !== undefined) {
      dispatch(fetchSubmissionsDetailsThunk({slug, token}));
    }
  }, []);

  if (problem === undefined || !problem.access.includes("DESCRIPTION")) {
    const courseId = courseIdByProblemSlug(slug!);

    return (
      <div>
        <Helmet>
          <title>{problem === undefined ? slug : problem.title}</title>
        </Helmet>
        <TwoColumnLayout
          key={slug}
          left={<HiddenProblem slug={slug} problem={problem} />}
          right={courseId !== undefined && <CourseMainContent courseId={courseId} />}
          leftLabel="Задача"
          rightLabel="Описание курса"
        />
      </div>
    );
  }

  return (
    <div>
      <Helmet>
        <title>{problem.title}</title>
      </Helmet>
      <TwoColumnLayout
        key={slug}
        left={<ProblemContent views={views} view={view} setView={setView} />}
        right={<Editor onSendSolution={() => setView("submissions")} />}
        leftLabel="Задача"
        rightLabel="Редактор"
      />
    </div>
  );
}
