import {useTranslation} from "../../../domain/i18n/i18n";
import s from "./ProblemSubmissions.module.css";
import cn from "classnames";
import {TestsList} from "./TestsList";
import {useDispatch, useSelector} from "../../../app/hooks";
import {useEffect} from "react";
import {fetchSubmissionsDetailsThunk} from "../../../redux/submissionsSlice";
import {formatDateTime} from "../../../domain/formatDateTime";
import {MarkdownRenderer} from "../ProblemDescription/MarkdownRenderer";

interface OneSubmissionProps {
  submissionToken: string,
  isPrevSubmission?: boolean,
  problemSlug: string,
}

export function OneSubmission({
  submissionToken,
  problemSlug,
  isPrevSubmission,
}: OneSubmissionProps) {
  const dispatch = useDispatch();
  const translate = useTranslation();
  const submissionPreview = useSelector(state =>
    state.problems[problemSlug].submissionsPreviews.find(p => p.token === submissionToken)
  );
  const submissionsHistory = useSelector(state => state.submissions);
  const submission = submissionsHistory.token2submission[submissionToken];

  useEffect(() => {
      dispatch(fetchSubmissionsDetailsThunk({slug: problemSlug, token: submissionToken}));
  }, []);

  if (submissionPreview === undefined) {
    return null;
  }

  const {status} = submissionPreview;
  const submittedAt = new Date(submissionPreview.submittedAt);

  if (status === "CANCELED") {
    return (
      <div className={s.wrapper}>
        <h1>Последняя отправка была отменена</h1>
      </div>
    );
  }

  if (status === "PENDING") {
    return (
      <div className={s.wrapper}>
        <div>
          <h1>{translate("submissions.testHeader")}</h1>
          <div className={cn(s.status, s.loading)}>
            <h3>{translate("submissions.loading")}</h3>
            <p>{translate("submissions.testsAreRunning")}</p>
          </div>
        </div>
      </div>
    );
  }

  if (submission === undefined) {
    return null;
  }

  const {submissionResult, code} = submissionsHistory.token2submission[submissionToken];
  const solutionMarkdown = `~~~js\n${code}\n~~~`;

  const prevSubmissionHeader = isPrevSubmission ? (
    <div>
      <h1>Отправка от {formatDateTime(submittedAt)}</h1>
      <MarkdownRenderer children={solutionMarkdown}/>
    </div>
  ) : null;

  if (status === "TIME_LIMIT") {
    return (
      <div className={s.wrapper}>
        <div>
          {prevSubmissionHeader}
          <h1>{translate("submissions.testHeader")}</h1>
          <div className={cn(s.status, s.timeLimit)}>
            <h3>TIME LIMIT</h3>
            <p>{translate("submissions.timeLimitExplained")}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={s.wrapper}>
      {prevSubmissionHeader}
      <TestsList submissionResult={submissionResult}/>
    </div>
  );
}
