import {useSelector} from "../../../app/hooks";
import s from "./MethodsHintWrapper.module.css";
import {MethodsHint} from "./MethodsHint";

interface MethodsHintWrapper {
  slug: string;
}

export function MethodsHintWrapper({slug}: MethodsHintWrapper) {
  const user = useSelector(s => s.user.user?.username);

  if (user === "tanny-may") {
    return (
      <div className={s.hints}>
        <p>Подсказки по методам, авторское решение и видео-разбор доступны при оплате подписки</p>
      </div>
    )
  }

  return (
    <MethodsHint slug={slug}/>
  );
}
