import i00 from "./img/00.png";
import i01 from "./img/01.png";
import i02 from "./img/02.png";
import i03 from "./img/03.png";
import i04 from "./img/04.png";
import i05 from "./img/05.png";

import s from "./HelpPageContent.module.css";
import {Link} from "react-router-dom";

export function HelpPageContent() {
  return (
    <div className={s.wrapper}>
      <h1 className={s.mainTitle}>Как решать задачи на maxcode.dev</h1>
      <div className={s.step}>
        <div className={s.description}>
          <h2>#0. Список задач</h2>
          <p>Лучше ориентироваться на <Link to="/roadmaps/javascript">Список задач по JavaScript</Link>, в котором задачи сгруппированы по темам и идут по увеличению сложности.</p>
          <p>Зеленым отмечаются задачи, которые вы решили. Оранжевым отмечаются задачи, которые вы отправляли, но решение не прошло все тесты.</p>
          <p>Все задачи доступны бесплатно. Единственное исключение — продвинутый курс по <Link to="/promo/async">Асинхронному программированию</Link>. В нем часть задач доступна после оплаты.</p>
        </div>
        <img className={s.preview} src={i00}/>
      </div>
      <div className={s.step}>
        <div className={s.description}>
          <h2>#1. Условие задачи</h2>
          <p>Как правило, необходимо реализовать функцию, которая решает задачу, описанную в условии.</p>
          <p>Единственное ограничение — нельзя менять название функции. При этом вы можете
            менять названия и количество аргументов, а также задавать им значения по умолчанию.</p>
          <p>Можно объявлять дополнительные функции и переменные, если это помогает упростить решение. Их можно
            создавать на самом верхнем уровне, снаружи функции.</p>
          <p>Обычно в условии есть пример вызова функции с <em>console.log</em>. Его можно скопировать себе в редактор, чтобы запускать код локально.</p>
          <p>На сайте сам пример или console.log в решение добавлять не нужно. Тестирующая система обращает внимание только на функцию и запускает ее с разными аргументами.</p>
        </div>
        <img className={s.preview} src={i01}/>
      </div>
      <div className={s.step}>
        <div className={s.description}>
          <h2>#2. Отправка решения</h2>
          <p>После нажатия на кнопку «Отправить» решение уходит на проверку. Через несколько секунд приходят результаты тестов.</p>
          <p>Тест — это запуск вашей функции с определенными входными данными. Тестирующая система ожидает, что функция
            вернет определенное значение для этих аргументов и сравнивает его с результатом вызова вашей функции.</p>
          <p>Если значения совпали, то тест зеленый, а если нет — красный. В случае красного теста можно опираться на описание и сообщение внутри теста.</p>
        </div>
        <img className={s.preview} src={i02}/>
      </div>
      <div className={s.step}>
        <div className={s.description}>
          <h2>#3. Успешное решение</h2>
          <p>Если решение верное, то все тесты покрасятся в зеленый.</p>
        </div>
          <img className={s.preview} src={i03}/>
      </div>
      <div className={s.step}>
        <div className={s.description}>
          <h2>#4. Авторское решение</h2>
          <p>После отправки корректного решения открывается Авторское решение. Иногда там представлено более одного способа решить задачу.</p>
        </div>
        <img className={s.preview} src={i04}/>
      </div>
      <div className={s.step}>
        <div className={s.description}>
          <h2>#5. Подсказки по методам</h2>
          <p>В разделе <Link to="/cheatsheets/javascript">Список методов в JavaScript</Link>, можно посмотреть, что принимает и возвращает каждый метод. По клику на строчку открывается пример использования и список задач, в которых можно потренироваться использовать метод.</p>
          <p>Попасть в этот раздел можно и из условия задачи, кликнув на метод в блоке «Подсказка по методам».</p>
        </div>
        <img className={s.preview} src={i05}/>
      </div>
    </div>
  )
}
