export const PromiseMethods = [
  {
    method: "Promise#then",
    argument: "onFulfilled + onRejected",
    returnedValue: "promise",
    mutable: false,
    nodeVersion: "0.12.0",
    year: 2015,
    tier: "A",
  },
  {
    method: "Promise#catch",
    argument: "onRejected",
    returnedValue: "promise",
    mutable: false,
    nodeVersion: "0.12.0",
    year: 2015,
    tier: "A",
  },
  {
    method: "Promise#finally",
    argument: "onFinally",
    returnedValue: "promise",
    mutable: false,
    nodeVersion: "10",
    year: 2018,
    tier: "A",
  },
  {
    method: "Promise.all",
    argument: "iterable",
    returnedValue: "promise",
    mutable: false,
    nodeVersion: "0.12.0",
    year: 2015,
    tier: "A",
  },
  {
    method: "Promise.allSettled",
    argument: "iterable",
    returnedValue: "promise",
    mutable: false,
    nodeVersion: "12.9.0",
    year: 2019,
    tier: "A",
  },
  {
    method: "Promise.race",
    argument: "iterable",
    returnedValue: "promise",
    mutable: false,
    nodeVersion: "0.12.0",
    year: 2015,
    tier: "A",
  },
  {
    method: "Promise.any",
    argument: "iterable",
    returnedValue: "promise",
    mutable: false,
    nodeVersion: "15.0.0",
    year: 2020,
    tier: "A",
  },
  {
    method: "Promise.resolve",
    argument: "value",
    returnedValue: "promise",
    mutable: false,
    nodeVersion: "0.12.0",
    year: 2015,
    tier: "A",
  },
  {
    method: "Promise.reject",
    argument: "value",
    returnedValue: "promise",
    mutable: false,
    nodeVersion: "0.12.0",
    year: 2015,
    tier: "A",
  },
  {
    method: "Promise.withResolvers",
    argument: "—",
    returnedValue: "promise + resolve + reject",
    mutable: false,
    nodeVersion: "22.0.0",
    year: 2024,
    tier: "A",
  },
] as const;

export type PromiseMethod = typeof PromiseMethods[number];

