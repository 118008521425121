import {Problem} from "../../../api/problems";
import {MarkdownRenderer} from "./MarkdownRenderer";
import {MethodsHintWrapper} from "./MethodsHintWrapper";


interface ProblemDescriptionProps {
  problem: Problem;
}

export function ProblemDescription({problem}: ProblemDescriptionProps) {
  if (problem.description === null) {
    return null;
  }


  return (
    <div>
      <MarkdownRenderer children={problem.description}/>
      <MethodsHintWrapper slug={problem.slug} />
    </div>
  );
}

