import s from "./Payment.module.css";
import {problemsPlural} from "../../domain/i18n/plural";
import {asyncCourse, MainCourses} from "../../domain/problems/MaxcodeCourses";
import {useEffect, useState} from "react";
import cn from "classnames";
import {sendPayment} from "../../api/payment";
import {formatPrice} from "../../domain/formatPrice";
import {useSelector} from "../../app/hooks";

const SellableCourses = [
  "async",
  "bits",
] as const;

export function isSellableCourses(courseId: string): courseId is SellableCourseId {
  return SellableCourses.includes(courseId as unknown as SellableCourseId);
}

export type SellableCourseId = typeof SellableCourses[number];

interface PaymentProps {
  courseId: SellableCourseId;
}

const courseId2price: Record<SellableCourseId, number> = {
  async: 14_900,
  bits: 10,
  // fp: 11_900,
  // oop: 5_900,
}

export type Promocode = {
  error: string,
} | {
  expiredAt: string,
  discount: number,
} | null;

export function AsyncCourseShortDescription() {
  const problems = asyncCourse.topics.flatMap(t => t.problems);

  return (
      <ul>
        <li>{problems.length} {problemsPlural(problems.length)} на промисы, колбэки и event loop</li>
        <li>120 дней доступа к тестирующей системе</li>
        <li>11+ часов видео с разбором решений</li>
      </ul>
    );
}

export function Payment({courseId}: PaymentProps) {
  const [code, setCode] = useState("");
  const [promocode, setPromocode] = useState<Promocode>(null);

  const courses = useSelector(s => s.user.courses);
  const asyncCourse = courses.find(course => ["async-pro", "async"].includes(course.courseId));

  useEffect(() => {
    if (code.length === 4) {
      if (code !== "2024") {
        setPromocode({
          error: "Промокод недействителен"
        });
      } else {
        setPromocode({
          discount: 99,
          expiredAt: new Date(2025).toISOString(),
        })
      }
    } else {
      setPromocode(null);
    }
  }, [code]);

  async function handleClick() {
    const yoomoneyUrl = await sendPayment(courseId + "-pro", null);
    window.location.href = yoomoneyUrl;
  }

  const course = MainCourses.find(x => x.id === courseId);

  if (course === undefined) {
    return null;
  }
  const problems = course.topics.flatMap(t => t.problems);

  const price = courseId2price[courseId];
  const discount = promocode !== null && "discount" in promocode
    ? promocode.discount
    : 0;

  const discountedPrice = price * (100 - discount) / 100;

  return (
    <div className={s.wrapper}>
      <h2>{course.title}</h2>

      <div className={s.price}>
        Стоимость <span className={cn(discount > 0 && s.strikethrough)}>{formatPrice(price)} ₽</span> {discount > 0 &&
        <span>{formatPrice(discountedPrice)} ₽</span>} / 4 месяца
        {courseId === "async" && (
          <ul className={s.list}>
            <li>{problems.length} {problemsPlural(problems.length)} на промисы, колбэки и event loop</li>
            <li>120 дней доступа к тестирующей системе</li>
            <li>11+ часов видео с разбором решений</li>
          </ul>
        )}
      </div>

      <button
        className={s.buyButton}
        onClick={handleClick}
        disabled={!(courseId === "async" ? asyncCourse === undefined : true)}
      >
        Перейти к оплате
      </button>

      {/*<div className={s.promo}>*/}
      {/*  <div>*/}
      {/*    У меня есть промокод*/}
      {/*  </div>*/}
      {/*  <div>*/}
      {/*    <input*/}
      {/*      value={code}*/}
      {/*      onChange={(e) => setCode(e.target.value)}*/}
      {/*      className={s.promoInput}*/}
      {/*    />*/}
      {/*    {promocode !== null && "discount" in promocode && <span>Скидка {discount}%</span>}*/}
      {/*    {promocode !== null && "error" in promocode && <span>{promocode.error}</span>}*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  )
}
