export const algoTopics = [
  {
    section: "Префиксные суммы",
    problems: [
      "pivot-index",
      "range-sum-immutable",
      "subarray-sum",
    ],
  },
  {
    section: "Сортировки",
    problems: [
      "sort",
      "merge-sort",
    ],
  },
  {
    section: "Интервалы",
    problems: [
      "max-population",
      "meeting-rooms",

    ],
  },
  {
    section: "Бинарные деревья",
    problems: [
      "complete-tree-nodes",
      "binary-tree-width",
    ],
  },
  // {
  //   section: "Монотонный стек",
  //   problems: [
  //     "daily-temperatures",
  //     "online-stock-span",
  //   ],
  // },
  {
    section: "Бинарный поиск",
    problems: [
      "search-in-matrix",
      "first-and-last-in-sorted-array",
      // "search-in-matrix-2",
    ],
  },
  {
    section: "Математика",
    problems: [
      "is-prime",
      "line-reflection",
    ],
  },
] as const;
