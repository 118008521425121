import {useSearchParams} from "react-router-dom";
import {
  arraysCourse,
  asyncCourse, fpCourse,
  isMaxcodeProblem,
  MaxcodeProblem,
  objectsCourse, oopCourse,
  recursionCourse
} from "../../domain/problems/MaxcodeCourses";
import {RoadmapProblems} from "../RoadmapProblems/RoadmapProblems";
import s from "./ProblemsCompilation.module.css"
import {useState} from "react";

export const MainPageCourses = [
  arraysCourse,
  objectsCourse,
  fpCourse,
  oopCourse,
  recursionCourse,
  asyncCourse,
];

const IDS_SEPARATOR = "~";

export function ProblemsCompilation() {
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const idsParam = searchParams.get("ids") ?? "";
  const problemIds = idsParam.split(IDS_SEPARATOR).filter(isMaxcodeProblem);

  function toggleCheckbox(id: MaxcodeProblem) {
    const updated = problemIds.includes(id)
      ? problemIds.filter(x => x !== id)
      : [...problemIds, id];

    setSearchParams({ids: updated.join(IDS_SEPARATOR)});
  }

  const allProblems = MainPageCourses.flatMap(course => course.topics.flatMap(t => t.problems));
  const sortedProblems = allProblems.filter(id => problemIds.includes(id));

  return (
    <div>
      {open && (
        <div className={s.coursesList}>
          {MainPageCourses.map(course => {
            return (
              <div className={s.courseColumn}>
                <h3>{course.title}</h3>
                <ul>{course.topics
                  .flatMap(t => t.problems)
                  .map(problem => (
                    <label className={s.problem}>
                      <input
                        type="checkbox"
                        onChange={() => toggleCheckbox(problem)}
                        checked={problemIds.includes(problem)}
                      />
                      {problem}
                    </label>
                  ))}
                </ul>
              </div>
            )
          })}
        </div>
      )}
      <RoadmapProblems
        sectionOffset={0}
        sections={[{
          problems: sortedProblems,
          title: "Домашнее задание",
          id: "none",
        }]}
      />
      <button onClick={() => setOpen(!open)}>Изменить список</button>
    </div>
  )
}
