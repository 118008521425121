import s from "./Profile.module.css"
import {SubmissionsHistory} from "../SubmissionsHistory/SubmissionsHistory";
import {useDispatch, useSelector} from "../../app/hooks";
import {GithubAuthWindow} from "../GithubAuthWindow/GithubAuthWindow";
import {logoutThunk} from "../../redux/userSlice";
import {useNavigate} from "react-router-dom";
import {Button} from "../Button/Button";
import {ProblemsProgress} from "../ProblemsProgress/ProblemsProgress";

export function Profile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);

  function handleClick() {
    dispatch(logoutThunk());
    navigate("/");
  }

  if (user === null) {
    return <GithubAuthWindow/>;
  }

  return (
    <div className={s.wrapper}>
      <div className={s.left}>
        <div className={s.personal}>
          <img className={s.avatar} src={user.avatarUrl}/>
          <div className={s.personalRight}>
            <span className={s.username}>@{user.username}</span>
            <Button className={s.logout} onClick={handleClick}>Выйти</Button>
          </div>
        </div>
        <ProblemsProgress/>
      </div>
      <div className={s.right}>
        <SubmissionsHistory/>
      </div>
    </div>
  )
}
