import React, {useState} from "react";
import s from "./MethodsTable.module.css";
import cn from "classnames";
import {JavascriptMethod} from "../../domain/problems/js/JavascriptMethods";
import {OperatorMethods} from "../../domain/problems/js/OperatorMethods";

type Comparator = (a: JavascriptMethod, b: JavascriptMethod) => number;
const comparators: Record<string, Comparator> = {
  name: (a, b) => a.method.localeCompare(b.method),
  year: (a, b) => a.year - b.year || a.nodeVersion.localeCompare(b.nodeVersion),
  returnedValue: (a, b) => a.returnedValue.localeCompare(b.returnedValue),
  argument: (a, b) => a.argument.localeCompare(b.argument),
  mutable: (a, b) => +a.mutable - +b.mutable,
  tier: (a, b) => a.tier.localeCompare(b.tier),
};

interface MethodsTableProps {
  methods: readonly JavascriptMethod[];
  onClick?: (m: JavascriptMethod) => void;
  fullMethodName?: boolean;
}

export function MethodsTable({
  methods,
  onClick,
  fullMethodName = false,
}: MethodsTableProps) {
  const [cmp, setCmp] = useState<Comparator>(() => comparators.tier);

  return (
    <div className={s.table}>
      <div className={cn(s.row, s.rowHeader)} key="header">
        <span onClick={() => setCmp(() => comparators.name)} className={s.methodName}>Метод</span>
        <span onClick={() => setCmp(() => comparators.mutable)} className={s.mutable}></span>
        <span onClick={() => setCmp(() => comparators.argument)} className={s.argument}>Принимает</span>
        <span onClick={() => setCmp(() => comparators.returnedValue)} className={s.returnedValue}>Возвращает</span>
        <span onClick={() => setCmp(() => comparators.year)} className={s.year}>Node.js</span>
        {/*<span onClick={() => setCmp(() => comparators.tier)} className={s.tier}></span>*/}
      </div>

      {[...methods]
        .sort(cmp)
        .map((methodObj) => {
          const {method, returnedValue, argument, tier, year, nodeVersion, mutable} = methodObj;
          const isMethod = method.includes("#");
          const isStaticMethod = method.includes(".");
          const [javascriptClass, methodName] = method.split(isStaticMethod ? "." : "#");

          let link;

          if (OperatorMethods.some(m => m.method === method)) {
            const snakedMethod = method.replaceAll(" ", "_");
            link = `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/${snakedMethod}`;
          } else {
            link = isMethod || isStaticMethod
              ? `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/${javascriptClass}/${methodName}`
              : `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/${method}`;
          }

          return (
            <div className={cn(s.row, s.rowData)} key={methodName} onClick={() => onClick?.(methodObj)}>
              <span className={s.methodName}>
                <a target="_blank" href={link} onClick={(e) => e.stopPropagation()}>
                  {(isStaticMethod || fullMethodName || (!isStaticMethod && !isMethod)) ? method : methodName}
                </a>
              </span>
              <span className={s.mutable}>{mutable ? "😈" : "😇"}</span>
              <span className={s.argument}>{argument}</span>
              <span className={s.returnedValue}>{returnedValue}</span>
              <span className={s.year} title={year.toString()}>
                {nodeVersion.startsWith("0") ? (nodeVersion === "0.10.0" ? "ES5" : "ES6") : `${nodeVersion.split(".")[0]}`}
              </span>
              {/*<span className={cn(s.tier, s[tier])}>{tier}</span>*/}
            </div>
          );
        })}
    </div>
  );
}
