export const StringMethods = [
  {
    method: "String#at",
    argument: "index",
    returnedValue: "value",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "B",
    example: `      
      "qwerty".at(-1) === "y"
    `,
  },
  {
    method: "String#charCodeAt",
    argument: "index",
    returnedValue: "charCode",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "B",
    example: `      
      "ABCD".charCodeAt(0) === 65
      "ABCD".charCodeAt(1) === 66
      "ABCD".charCodeAt() === 65
    `,
  },
  {
    method: "String#endsWith",
    argument: "string",
    returnedValue: "boolean",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
    example: `      
      "qwerty".endsWith("rty") === true
    `,
  },
  {
    method: "String#includes",
    argument: "string",
    returnedValue: "boolean",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
    example: `      
      "qwerty".endsWith("wer") === true
    `,
  },
  {
    method: "String#indexOf",
    argument: "string",
    returnedValue: "index",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "B",
    example: `      
      "qwerty".endsWith("ert") === 2
      "qwerty".endsWith("asd") === -1
    `,
  },
  {
    method: "String#lastIndexOf",
    argument: "string",
    returnedValue: "index",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "B",
    example: `      
      "qweqweqwe".lastIndexOf("qw") === 6
      "qweqweqwe".lastIndexOf("w1") === -1
    `,
  },
  {
    method: "String#localeCompare",
    argument: "string",
    returnedValue: "number",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
    example: `      
      "biuf".localeCompare("jnjio") === -1 
      "qwe".localeCompare("asdfghj") === 1 
      "ab".localeCompare("abcd") === -1 
      "ab".localeCompare("ab") === -0 
    `,
  },
  {
    method: "String#match",
    argument: "regexp",
    returnedValue: "array",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "String#padEnd",
    argument: "length",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "String#padStart",
    argument: "length",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "String#repeat",
    argument: "number",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "B",
  },
  {
    method: "String#replace",
    argument: "regexp | string",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "String#replaceAll",
    argument: "regexp | string",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "String#slice",
    argument: "index",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "String#split",
    argument: "string",
    returnedValue: "array",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "String#startsWith",
    argument: "string",
    returnedValue: "boolean",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "String#toLowerCase",
    argument: "—",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "String#toUpperCase",
    argument: "—",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
  },
  {
    method: "String#trim",
    argument: "—",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "B",
  },
  {
    method: "String#trimEnd",
    argument: "—",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "B",
  },
  {
    method: "String#trimStart",
    argument: "—",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "B",
  },


  {
    method: "String#charAt",
    argument: "index",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "D",
  },
  {
    method: "String#codePointAt",
    argument: "index",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "D",
  },
  {
    method: "String#concat",
    argument: "string",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "D",
  },
  {
    method: "String#isWellFormed",
    argument: "—",
    returnedValue: "boolean",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "D",
  },
  {
    method: "String#matchAll",
    argument: "regexp",
    returnedValue: "iterator",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "D",
  },
  {
    method: "String#search",
    argument: "regexp",
    returnedValue: "index",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "D",
  },
  {
    method: "String#substring",
    argument: "index",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "D",
  },
  {
    method: "String#toLocaleLowerCase",
    argument: "—",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "D",
  },
  {
    method: "String#toLocaleUpperCase",
    argument: "—",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "D",
  },
  {
    method: "String#toString",
    argument: "—",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "D",
  },
  {
    method: "String#toWellFormed",
    argument: "—",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "D",
  },
  {
    method: "String#valueOf",
    argument: "—",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "D",
  },
  {
    method: "String.fromCharCode",
    argument: "code",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "B",
  },
  {
    method: "String.fromCodePoint",
    argument: "code",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "D",
  },
  {
    method: "String.raw",
    argument: "string",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "D",
  },

] as const;










































export type StringMethod = typeof StringMethods[number];

