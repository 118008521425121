import {TwoColumnLayout} from "../../components/Layout/TwoColumnLayout";
import {useParams} from "react-router-dom";
import {CourseId, courseId2topics} from "../../domain/problems/courses";
import React, {Fragment} from "react";
import {CourseMainContent} from "../../components/CoursePage/CourseMainContent/CourseMainContent";
import {useSelector} from "../../app/hooks";
import {Helmet} from "react-helmet-async";
import {CourseProblemsContent} from "../../components/CoursePage/CourseProblemsList/CourseProblemsContent";

export function CourseProblemsPage() {
  const {courseId} = useParams<{ courseId: CourseId }>();
  const lang = useSelector(state => state.settings.lang);

  if (courseId === undefined || !(courseId in courseId2topics)) {
    return null;
  }

  const {title} = courseId2topics[courseId][lang];

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <TwoColumnLayout
        key={courseId!}
        left={<CourseProblemsContent courseId={courseId!} />}
        right={<CourseMainContent courseId={courseId!} />}
        leftLabel="Список задач"
        rightLabel="Описание курса"
      />
    </Fragment>
  );
}
