import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  cancelSubmission,
  getSubmissionDetails,
  getSubmissionResult,
  getUserSubmissions,
  sendSubmission,
  SubmissionDetails,
  SubmissionStatus
} from "../api/submissions";
import {createAsyncThunk} from "../app/hooks";
import {problemsSlice} from "./problemsSlice";
import {SubmissionPreviewWithCode} from "../api/problems";
import {logoutThunk} from "./userSlice";

interface State {
  submissionsHistory: SubmissionPreviewWithCode[],
  token2submission: Record<string, SubmissionDetails>,
  slug2openSubmission: Record<string, string | null>,
}

const initialState: State = {
  submissionsHistory: [],
  token2submission: {},
  slug2openSubmission: {},
};

export const submissionsSlice = createSlice({
  name: "submissions",
  initialState,
  reducers: {
    setOpenSubmission(state, action: PayloadAction<{
      slug: string,
      token: string | null,
    }>) {
      const {token, slug} = action.payload;
      state.slug2openSubmission[slug] = token;
    },
    setSubmissionByToken(state, action: PayloadAction<{
      slug: string,
      token: string,
      submissionDetails: SubmissionDetails
    }>) {
      const {token, submissionDetails} = action.payload;
      state.token2submission[token] = submissionDetails;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSubmissionsDetailsThunk.fulfilled, (state, action: PayloadAction<{
      slug: string,
      token: string,
      submissionDetails: SubmissionDetails
    }>) => {
      const {token, submissionDetails} = action.payload;
      state.token2submission[token] = submissionDetails;
    }).addCase(fetchUserSubmissionsThunk.fulfilled, (state, action) => {
      state.submissionsHistory = action.payload;
    }).addCase(logoutThunk.pending, (state) => {
      return initialState;
    });
  },
});

export const fetchUserSubmissionsThunk = createAsyncThunk(
  'submissions/fetchUserSubmissions',
  (page: number) => getUserSubmissions(page),
);

export const cancelSubmissionsThunk = createAsyncThunk(
  'submissions/cancelSubmission',
  ({submissionId}: { submissionId: string, slug: string }) => {
    return cancelSubmission(submissionId);
  },
);


export const fetchSubmissionsDetailsThunk = createAsyncThunk(
  'submissions/fetchProblemSubmissionsHistory', async ({slug, token}: { slug: string, token: string }, thunkAPI) => {
    const actualDetails = thunkAPI.getState().submissions.token2submission[token];
    if (actualDetails !== undefined) {
      throw new Error(`Data for ${token} submission have been loaded already.`);
    }
    const submissionDetails = await getSubmissionDetails(token);
    if (submissionDetails.submissionPreview.status === "PENDING") {
      submissionRetry(
        token,
        slug,
        submissionDetails.code,
        submissionDetails.submissionPreview.problemId,
        thunkAPI,
      );
    }
    return {slug, token, submissionDetails};
  });

export const FAKE_TOKEN = "FAKE_TOKEN";

export const fetchSubmission = createAsyncThunk(
  'submissions/fetchProblems', async (
    {code, problemId, slug}: { code: string, problemId: number, slug: string },
    thunkAPI,
  ) => {
    const {lang} = thunkAPI.getState().settings;

    const action = {
      slug,
      token: FAKE_TOKEN,
      submissionPreview: {
        status: "PENDING" as SubmissionStatus,
        token: FAKE_TOKEN,
        problemId,
        problemSlug: slug,
        submittedAt: new Date().toISOString(),
      },
    };

    thunkAPI.dispatch(problemsSlice.actions.addSubmission(action));
    thunkAPI.dispatch(submissionsSlice.actions.setOpenSubmission({slug, token: FAKE_TOKEN}));

    const token = await sendSubmission(code, slug, lang);
    thunkAPI.dispatch(submissionsSlice.actions.setOpenSubmission({slug, token}));
    thunkAPI.dispatch(problemsSlice.actions.addSubmission({
      ...action,
      token: FAKE_TOKEN,
      submissionPreview: {
        ...action.submissionPreview,
        token,
      }
    }));

    await submissionRetry(
      token,
      slug,
      code,
      problemId,
      thunkAPI,
    );
  });

type Thunk = Parameters<Parameters<typeof createAsyncThunk>[1]>[1];

async function submissionRetry(
  token: string,
  slug: string,
  code: string,
  problemId: number,
  thunkAPI: Thunk,
) {
  let count = 0;

  while (true) {
    await new Promise(r => setTimeout(r, 1000));
    const submissionResult = await getSubmissionResult(token);

    const initialSubmissionPreview = {
      status: submissionResult.status,
      token,
      problemId,
      problemSlug: slug,
      submittedAt: new Date().toISOString(),
    }

    thunkAPI.dispatch(problemsSlice.actions.addSubmission({
      slug,
      token,
      submissionPreview: initialSubmissionPreview,
    }));

    if (submissionResult.status !== "PENDING") {
      const action = {
        slug,
        token: token,
        submissionDetails: {
          submissionPreview: {
            ...initialSubmissionPreview,
            status: submissionResult.status,
          },
          code,
          submissionResult,
        }
      };
      thunkAPI.dispatch(submissionsSlice.actions.setSubmissionByToken(action));
      return;
    }

    count++;
    if (count > 10) {
      return;
    }
  }
}
