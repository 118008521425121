export const SetMethods = [
  {
    method: "Set#add",
    argument: "value",
    returnedValue: "this",
    mutable: true,
    nodeVersion: "0.12.0",
    year: 2015,
    tier: "A",
  },
  {
    method: "Set#clear",
    argument: "—",
    returnedValue: "—",
    mutable: true,
    nodeVersion: "0.12.0",
    year: 2015,
    tier: "A",
  },
  {
    method: "Set#delete",
    argument: "value",
    returnedValue: "boolean",
    mutable: true,
    nodeVersion: "0.12.0",
    year: 2015,
    tier: "A",
  },
  {
    method: "Set#entries",
    argument: "—",
    returnedValue: "iterator",
    mutable: false,
    nodeVersion: "0.12.0",
    year: 2015,
    tier: "A",
  },
  {
    method: "Set#forEach",
    argument: "callback",
    returnedValue: "—",
    mutable: false,
    nodeVersion: "0.12.0",
    year: 2015,
    tier: "A",
  },
  {
    method: "Set#has",
    argument: "value",
    returnedValue: "boolean",
    mutable: false,
    nodeVersion: "0.12.0",
    year: 2015,
    tier: "A",
  },
  {
    method: "Set#keys",
    argument: "—",
    returnedValue: "iterator",
    mutable: false,
    nodeVersion: "0.12.0",
    year: 2015,
    tier: "A",
  },
  {
    method: "Set#values",
    argument: "—",
    returnedValue: "iterator",
    mutable: false,
    nodeVersion: "0.12.0",
    year: 2015,
    tier: "A",
  },
  {
    method: "Set#difference",
    argument: "Set",
    returnedValue: "Set",
    mutable: false,
    nodeVersion: "22",
    year: 2024,
    tier: "A",
  },
  {
    method: "Set#intersection",
    argument: "Set",
    returnedValue: "Set",
    mutable: false,
    nodeVersion: "22",
    year: 2024,
    tier: "A",
  },
  {
    method: "Set#isDisjointFrom",
    argument: "Set",
    returnedValue: "boolean",
    mutable: false,
    nodeVersion: "22",
    year: 2024,
    tier: "A",
  },
  {
    method: "Set#isSubsetOf",
    argument: "Set",
    returnedValue: "boolean",
    mutable: false,
    nodeVersion: "22",
    year: 2024,
    tier: "A",
  },
  {
    method: "Set#isSupersetOf",
    argument: "Set",
    returnedValue: "boolean",
    mutable: false,
    nodeVersion: "22",
    year: 2024,
    tier: "A",
  },
  {
    method: "Set#symmetricDifference",
    argument: "Set",
    returnedValue: "Set",
    mutable: false,
    nodeVersion: "22",
    year: 2024,
    tier: "A",
  },
  {
    method: "Set#union",
    argument: "Set",
    returnedValue: "Set",
    mutable: false,
    nodeVersion: "22",
    year: 2024,
    tier: "A",
  },
] as const;

export type SetMethod = typeof SetMethods[number];

