import s from "./GithubAuthLink.module.css"
import cn from "classnames";

export const generateGithubAuthUrl = () => {
  const query = new URLSearchParams();

  const path = window.location.pathname;
  const encodedPath =  window.encodeURIComponent(path);
  const redirectUri = process.env.REACT_APP_redirect_uri! + `?path=${encodedPath}`;

  query.append("client_id", process.env.REACT_APP_client_id!);
  query.append("redirect_uri", redirectUri);
  query.append("scope", "user:email");
  query.append("response_type", "code");

  return "https://github.com/login/oauth/authorize/?" + query.toString();
}

interface GithubAuthLinkProps {
  className?: string;
}

export function GithubAuthLink({ className }: GithubAuthLinkProps) {
  const githubAuthUrl = generateGithubAuthUrl();

  return (
    <a
      className={cn(s.link, className)}
      href={githubAuthUrl}
    >
      Github Login
    </a>
  );
}
