import {useSearchParams} from "react-router-dom";
import {isSellableCourses, Payment} from "../../components/Payment/Payment";
import {OneColumnLayout} from "../../components/Layout/OneColumnLayout";
import s from "../AsyncCourseLanding/AsyncCourseLanding.module.css";
import {AsyncProblemsDescription} from "../../components/CoursePage/CourseDescription/AsyncCourseDescription";
import {CourseProblemsList} from "../../components/CoursePage/CourseProblemsList/CourseProblemsList";
import React from "react";

export function PaymentPage() {
  const [searchParams] = useSearchParams();
  const courseId = searchParams.get("courseId");

  if (courseId === null || !isSellableCourses(courseId)) {
    return <div>No course id</div>;
  }

  return <OneColumnLayout content={
    <div>
      <Payment courseId={courseId}/>
      <div className={s.content}>
        <div className={s.left}>
          <div className={s.problemsDescription}>
            <AsyncProblemsDescription />
          </div>
        </div>
        <div className={s.right}>
          <div className={s.problemsList}>
            <CourseProblemsList courseId="async" />
          </div>
        </div>
      </div>
    </div>
  }/>
}
