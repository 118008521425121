import s from "./CourseDescription.module.css";

export function OopCourseDescription() {
  return (
    <div className={s.fp}>
      <h2>Какие темы разбираются в курсе</h2>

      <h3>Classes</h3>
      <p>Начнем с реализации собственных
        классов. <code>HttpRouter</code>, <code>QueryParams</code>, <code>BrowserHistory</code> и <code>VersionManager</code> решают
        прикладные задачи, связанные с вебом.</p>

      <h3>Descriptors</h3>
      <p>Разбираемся, как добавлять сеттеры и геттеры. Используем <code>Object.defineProperty</code> для «заморозки»
        объекта.</p>

      <h3>Extend Prototype</h3>
      <p>Расширяем стандартные классы <code>Array</code>, <code>Object</code> и <code>Function</code>.</p>

      <h3>Proto</h3>
      <p>Разбираем отличия <code>prototype</code> и <code>__proto__</code>. Знакомимся с реальными примерами
        использования Object.create.</p>

      <h3>This</h3>
      <p>Рассматриваем все особые варианты, чему может быть равен <code>this</code> в зависимости от способа вызова
        функции: <code>new</code>, <code>call</code>/<code>apply</code>, <code>bind</code>, стрелочная функция.</p>

      <h3>Inheritance</h3>
      <p>Переписываем наследование классов через прототипы, разбираясь с цепочкой прототипов.</p>
    </div>
  );
}
