import cn from "classnames";
import s from "./Button.module.css";

interface ButtonProps {
  onClick: () => void;
  children: string;
  className?: string;
}

export function Button({onClick, children, className}: ButtonProps) {
  return (
    <button
      className={cn(s.button, className)}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
