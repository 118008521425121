import {CourseId} from "../courses";
import {stackTopics} from "./stack";
import {prefixSum} from "./prefixSum";
import {binTreeTopics} from "./binTree";
import {binSearch} from "./binSearch";
import {dfs} from "./dfs";
import {backtracking} from "./backtracking";

export const LeetcodeCourses = [
  {
    id: "prefix-sum",
    title: "Префиксные суммы",
    topics: prefixSum,
  },
  {
    id: "bintree",
    title: "Бинарные деревья",
    topics: binTreeTopics,
  },
  {
    id: "stack",
    title: "Стек. Монотонный стек",
    topics: stackTopics,
  },
  {
    id: "heap",
    title: "Куча. Очередь с приоритетом",
    topics: [],
  },
  {
    id: "binsearch",
    title: "Бинарный поиск",
    topics: binSearch,
  },
  {
    id: "sliding-window",
    title: "Sliding Window",
    topics: [],
  },
  {
    id: "dfs",
    title: "Обход в глубину",
    topics: dfs.map(x => ({
      section: x.section,
      problems: x.problems.map(x => ({
        id: x.title.split(". ")[0],
        title: x.title.split(". ")[1],
        slug: x.slug,
        difficulty: "medium",
      })),
    })),
  },
  {
    id: "backtracking",
    title: "Бэктрекинг",
    topics: backtracking.map(x => ({
      section: x.section,
      problems: x.problems.map(x => ({
        id: x.title.split(". ")[0],
        title: x.title.split(". ")[1],
        slug: x.slug,
        difficulty: "medium",
      })),
    })),
  },
  {
    id: "list",
    title: "Списки",
    topics: [],
  },
  {
    id: "string",
    title: "Строки",
    topics: [],
  },
  {
    id: "union-find",
    title: "Union Find",
    topics: [],
  },
  {
    id: "dynamic",
    title: "Динамическое программирование",
    topics: [],
  },
  {
    id: "sorting",
    title: "Сортировки",
    topics: [],
  },
  {
    id: "greedy",
    title: "Жадные алгоритмы",
    topics: [],
  },
  {
    id: "graphs",
    title: "Графы",
    topics: [],
  },
  {
    id: "two-pointers",
    title: "Два указателя",
    topics: [],
  },
  {
    id: "geometry",
    title: "Геометрия",
    topics: [],
  },
  {
    id: "trees",
    title: "Деревья",
    topics: [],
  },
] as const;

export const LEETCODE_COURSES_IDS = LeetcodeCourses.map(x => x.id);
export const leetcodeCourse2Topics = Object.fromEntries(LeetcodeCourses.map(({id, topics, title}) => [
  id,
  {topics, title},
]));

export function isLeetcodeCourse(courseId: CourseId): courseId is LeetcodeCourseId {
  return LEETCODE_COURSES_IDS.includes(courseId as LeetcodeCourseId);
}

export type LeetcodeCourse = typeof LeetcodeCourses[number];
export type LeetcodeCourseId = typeof LEETCODE_COURSES_IDS[number];
