import React from "react";
import s from "./LeetcodeCourseProblemsList.module.css";
import {leetcodeCourse2Topics, LeetcodeCourseId} from "../../../domain/problems/leetcode/LeetcodeCourses";

interface LeetcodeCourseProblemsListProps {
  courseId: LeetcodeCourseId;
}

export function LeetcodeCourseProblemsList({courseId}: LeetcodeCourseProblemsListProps) {
  const {topics, title} = leetcodeCourse2Topics[courseId];

  return (
    <ol className={s.contents}>
      {topics.map(({section, problems}) => {
        return (
          <li key={section} className={s.section}>
            <span className={s.sectionTitle}>{section}</span>
            <ul className={s.leetcodeSectionList}>
              {problems.map(({id, slug, title}) => {
                const url = `https://leetcode.com/problems/${slug}/description/`;
                return (
                  <li className={s.leetcodeLink}>
                    <span className={s.id}>{id}</span>
                    <a href={url} target="_blank">{title}</a>
                  </li>
                );
              })}
            </ul>
          </li>
        )
      })}
    </ol>
  );
}
